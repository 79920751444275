<template>
    <div class="p-d-flex  p-flex-column" style="width:100%; height: 100%; position: absolute;" v-if="Finished || BeforePublic" >
        <div class=" p-as-center" style="display: table;height:100%;">
            <div style="margin: 0 auto; display: table-cell;   vertical-align: middle;width: 100%; ">
                <div class="">
                    <div>
                        <div class="p-text-center loginDocumentText " style="font-size: 20px">
                            {{DocumentName}}
                        </div>
                        <div v-if="Finished" class="p-text-center loginLogoMini">{{$t('utils.finishedFormText')}}</div>
                        <div v-if="BeforePublic" class="p-text-center loginLogoMini">{{$t('utils.beforePublicFormText')}}</div>
                    </div>
                </div>
            </div>
        </div>
        <BankInvoicePaperFooter />
    </div>
    <div class="p-d-flex  p-flex-column" style="width:100%; height: 100%; position: absolute;" v-else >
        <div class="container width-100-phone" style="padding-top: 5px; text-align: right;" >
            <Dropdown v-model="selectedLanguage" :options="languages" optionLabel="name" optionValue="value" class="selectLanguageInputFormDropdown" />
        </div>
        <div class=" p-as-center  width-100-phone" style="display: table;height:100%;">
            <div style="margin: 0 auto; display: table-cell;   vertical-align: middle;" class="container doorContainer">
                <div class="">
                    <div class="p-text-center loginDocumentText">
                        <h1 class="" style="text-align: left; font-size: 20px; font-weight: bold; padding-left: 8px; border-left: 4px solid #4a525a;word-break: break-word;"> {{DocumentName}} </h1>
                    </div>
                </div>
                <div class="p-col-12 p-grid no_padding no_margin">
                    <template v-for="MessageInfo in FormTemplateShowMessageBeforeList">
                        <template v-if="MessageInfo.IsActive">
                            <div class="p-col-12 p-nogutter p-fluid p-grid no_padding" style="">
                                <div class="p-field MessageBeforeSendingFormContainer" :class="{'MessageBeforeSendingFormContainerMobile': isMobile.isMobile()}">
                                    <!-- <Textarea v-model="MessageInfo.Message" rows="5" readonly=true style="" autoResize /> -->
                                    <FormMessageEditor :content="MessageInfo.Message" class="formMessageEditorMessageInfo formMessageEditorMessageInfoInPreFormMessage" :disabled="true" />
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="p-col-12 p-nogutter p-fluid p-grid MessageBeforeSendingFormContainer" :class="{'MessageBeforeSendingCheckboxFormContainerMobile': isMobile.isMobile()}" >
                        <template v-if="FormTemplateShowBefore.IsSelectBoxActive" >
                            <template v-for="(SelectBox, selectBoxIndex) in FormTemplateShowSelectboxBeforeList.filter(x => x.SelectBoxText)" :key="SelectBox._id">
                                <template v-if="SelectBox.SelectBoxText">
                                    <div class="p-field-radiobutton p-col-12 p-nogutter no_padding p-fluid " v-bind:class="{'no_margin_bottom': (selectBoxIndex == FormTemplateShowSelectboxBeforeList.filter(x => x.SelectBoxText).length - 1)}">
                                        <Checkbox :value="SelectBox.SelectBoxValue" v-model="SelectedBoxValueList" @change.prevent="onCheckBoxInput" />
                                        <FormMessageEditor class="formMessageEditorSelectBoxViewer border_none" :content="SelectBox.SelectBoxHTML" :disabled="true" />
                                    </div>
                                </template>
                            </template>
                        </template>
                    </div>
                </div>
                <div class="p-field p-col-12 p-nogutter MessageBeforeSendingFormTurnstileContainer" :class="{'MessageBeforeSendingFormTurnstileContainerMobile': isMobile.isMobile()}" >
                    <div id="turnstile"></div>
                </div>

                <div class="p-field p-col-12 p-nogutter MessageBeforeSendingFormContainer text-center p-pb-0">
                    <Button style="font-size: 14px;" type="button" class="p-button-outlined p-button-secondary light-disabled-color" :label="$t('utils.next') + ' >'" :disabled="!CaptureSuccess || !CheckboxSuccess" @click="onNextButtonClick" />
                </div>
                <div class="text-center yellow-text" style="font-size: 12px; margin-top: 6px">
                    {{$t('preFormMessage.conditionActiveNextButton')}}
                </div>
            </div>
        </div>
        <template v-if="displayProgressSpinner">
            <div class="miniProgressSpinnerContainer">
                <ProgressSpinner strokeWidth="4" />
            </div>
        </template>
        <div class="p-text-center loginLogoMiniHello">Hello Paperless World</div>
        <BankInvoicePaperFooter />
    </div>
</template>
<script>
import http from '../mixins/api'
import ProgressSpinner from 'primevue/progressspinner';

import Button from 'primevue/button';
import BankInvoicePaperFooter from '@/components/BankInvoicePaperFooter';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import Checkbox from 'primevue/checkbox';
import isMobile from '@/mixins/device'
import FormMessageEditor from '@/components/formMessageEditor';


export default {
    name: 'PreFormMessage',
    components: {
        Button,
        BankInvoicePaperFooter,
        ProgressSpinner,
        Dropdown,
        Textarea,
        Checkbox,
        FormMessageEditor,
    },  
    data: function(){
        return {
            DocumentName: "",
            TemplateId: null,
            Finished: false,
            BeforePublic: false,
            FormTemplateShowBefore: {},
            FormTemplateShowMessageBeforeList: [],
            FormTemplateShowSelectboxBeforeList: [],
            SelectedBoxValueList: [],
            isMobile,
            CaptureSuccess: false,
            CheckboxSuccess: false,
        }
    },
    created() {
        const self = this 
        const alertHandler = (e) => {
            e.returnValue = self.$i18n.tc("utils.backInInputForm");
        }
        window.removeEventListener('beforeunload', alertHandler);
        window.addEventListener('beforeunload', alertHandler);
    },
    computed: {
        selectedLanguage: {
            get: function() {
                return this.$store.getters["applicantStore/getUserLanguage"]
            },
            set: function(value) {
                this.$store.commit("applicantStore/setUserLanguage", value)
            },
        },

        languages: {
            get: function() {
                return this.$store.getters["applicantStore/getUserLanguageList"]
            },
        },

    },
    async beforeRouteEnter(route, redirect, next) {
        const DepartmentUUID = route.params.departmentUUID
        const LinkUUID = route.params.linkUUID
        const res = await http.post('/form/IsPublicedFormTemplate', {DepartmentUUID: DepartmentUUID, LinkUUID: LinkUUID}, {}, false, false);
        if(res.Success) {
            next(vm => vm.setData({id: res.TemplateId, DocumentName: res.DocumentName}))

            const res_passed = await http.post("/form/checkFormTemplatePreFormMessage", {DepartmentUUID: DepartmentUUID, LinkUUID: LinkUUID}, {}, false, false)
            if (res_passed.Success) {
                if(!res_passed.IsActive) {
                    next({name: "Doors", params: {departmentUUID: DepartmentUUID, linkUUID: LinkUUID}})
                    return
                }
            }
            next(vm => vm.setData({id: res.TemplateId, DocumentName: res.DocumentName}))


        } else {
            next(vm => vm.wrongPage({DocumentName: res.DocumentName, isBefore: res.isBefore}))
        }
    },
    methods: {
        wrongPage: function(data) {
            this.DocumentName = data.DocumentName
            if (data.isBefore) {
                this.BeforePublic = true
            } else {
                this.Finished = true
            }
        },
        setData: async function(data) {
            this.TemplateId = data.id
            this.DocumentName = data.DocumentName
            this.$store.commit("applicantStore/setInitUserLanguage")
            const res = await http.post("/form/getApplicantFormTemplatePreFormMessage", {DepartmentUUID: this.$route.params.departmentUUID, LinkUUID: this.$route.params.linkUUID}, {}, false, false)
            if(res.Success) {
                this.FormTemplateShowBefore = res.FormTemplateShowBefore
                this.FormTemplateShowMessageBeforeList = res.FormTemplateShowMessageBeforeList
                this.FormTemplateShowSelectboxBeforeList = res.FormTemplateShowSelectboxBeforeList
                const SelectboxTextList = this.FormTemplateShowSelectboxBeforeList.map(x => x.SelectBoxText).filter(x => x)
                if (!this.FormTemplateShowBefore.IsSelectBoxActive || SelectboxTextList.length == 0) {
                    this.CheckboxSuccess = true
                }
                console.log(this.FormTemplateShowBefore)
                if (!this.FormTemplateShowBefore.IsAuthActive) {
                    this.CaptureSuccess = true
                }
            }
            


            if(!this.CaptureSuccess) {
                const self = this;
                turnstile.render('#turnstile', {
                    sitekey: process.env.VUE_APP_TURNSTILE_SECRETKEY,
                    callback: function(token) {
                        self.CaptureSuccess = true
                    }
                })
            }

        },

        onCheckBoxInput: function(e) {
            console.log(e)
            const SelectboxValueList = this.FormTemplateShowSelectboxBeforeList.map(x => x.SelectBoxText ? x.SelectBoxValue : "").filter(x => x)
            if (JSON.stringify(this.SelectedBoxValueList.sort()) == JSON.stringify(SelectboxValueList.sort())) {
                this.CheckboxSuccess = true
                
            } else {
                this.CheckboxSuccess = false
            }
        },

        onNextButtonClick: async function() {
            const SelectboxValueList = this.FormTemplateShowSelectboxBeforeList.map(x => x.SelectBoxText ? x.SelectBoxValue : "").filter(x => x)
            console.log(JSON.stringify(this.SelectedBoxValueList.sort()) , JSON.stringify(SelectboxValueList.sort()))
            if (this.CaptureSuccess && this.CheckboxSuccess) {
                const res_url = await http.post("/form/GetFormURLByTemplateIdInDoor", {DepartmentUUID: this.$route.params.departmentUUID, LinkUUID: this.$route.params.linkUUID }, {}, false, false)
                if(res_url.Success) {
                    this.$store.commit('applicantStore/setIsPassedBeforeFormMessage', true)
                    this.$router.push({path: res_url.Path})
                } else {
                    this.$router.push({path: "/notfoundforms"})
                }
            }

        },
    }
}
</script>

<style scoped>
.loginDocumentText {
    text-align: center;
    font-size: 46pt;
    font-style: normal;
    font-weight: 300;
    color: #666666;
    /* font-family: 'MS P明朝',courier,'SF Pro Icons','Helvetica Neue',Helvetica,Arial,sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    max-width: 100%;
}
.disabledColor {
    color: #495057;
}
.MessageBeforeSendingFormContainer {
    margin: 10px auto 0px auto;
    width: 40vw;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.MessageBeforeSendingFormContainerMobile {
    margin-top: 20px;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
}
.MessageBeforeSendingFormTurnstileContainer {
    margin: 10px auto 0px auto;
    width: 40vw;
    margin-top: 0px;
    padding-left: 1rem; 
    padding-top: 0px;
}
.MessageBeforeSendingFormTurnstileContainerMobile {
    width: 100%;
    padding-left: 0rem; 
    padding-right: 0px;
}
.MessageBeforeSendingCheckboxFormContainerMobile {
    width: 100%;
    padding-left: 0rem; 
    padding-right: 0px;
}
.MessageBeforeSendingFormContainer textarea,
.MessageBeforeSendingFormContainer textarea:focus {
    min-height: 103px;
    width: 100%; 
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 3px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    border: none;
    border-radius: 6px;
}
.checkBoxLabel {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    line-height: 1.5em;
}
.checkBoxLabel  .p-checkbox{
    margin-right: 4px;
}
</style>
