<template>
    <div class="p-d-flex  p-flex-column" style="width:100%; height: 100%; position: absolute;" >
        <div class=" p-as-center" style="display: table;height:100%;">
            <div style="margin: 0 auto; display: table-cell;   vertical-align: middle;width: 100%; ">
                <div class="">
                    <div>
                        <div class="p-text-center loginLogoText">
                            <span style="position: relative;">
                                PAPER
                                <span class="loginLogoMini" style="font-size: 17px;position: absolute; left: 3px; top: -10px;">BankInvoice</span>
                            </span>
                        </div>
                    </div>
                    <div class="p-text-center loginLogoMini">Hello Paperless World</div>
                </div>
                <div class="p-field">
                    <template v-if="showEmailForm">
                        <div class=" p-grid p-nogutter">
                            <label style="font-size: 14px;" >{{$t("postedForm.inputEmail")}}</label>
                        </div>
                        <div class=" p-grid p-nogutter">
                            <InputText class="p-col"  v-model="Email" type="email" placeholder="Email" ref="certificationEmailField" autoFocus/>
                            <div class=" p-col-fixed" style="" >
                                <Button type="button" class="p-button-outlined p-button-secondary" :label="$t('utils.send')" @click="getLinkButtonClick" />
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <template v-if="displayProgressSpinner">
        <div class="miniProgressSpinnerContainer">
            <ProgressSpinner strokeWidth="4" />
        </div>
    </template>
</template>

<script>
import http from '../mixins/api'
import InputText from 'primevue/inputtext';
import ProgressSpinner from 'primevue/progressspinner';

import Button from 'primevue/button';


export default {
    name: 'postedFormDoor',
    components: {
        Button,
        InputText,
        ProgressSpinner,
    },
    data: function() {
        return {
            DocumentName: null,
            showEmailForm: true,
            Email: null,
            SendEmail: null,
            FormId: null,
            validInputCeNo: false,
            No: null,
            displayProgressSpinner: false,
        }
    },
    async beforeRouteEnter(route, redirect, next) {
        const query = route.query
        const id = query.id
        localStorage.removeItem("formUserToken")
        localStorage.removeItem("formUserInfo")
        next(vm => vm.setData(id))

    },

    methods: {
        setData: function(id) {
            this.FormId = id
        },

        ToFormByEmail: async function(e) {
            await (this.showEmailForm = !this.showEmailForm)
            if(this.showEmailForm) {
                this.$refs.certificationEmailField.$el.focus()
            }
        },

        getLinkButtonClick: async function(e){
            const Email = this.Email;
            this.displayProgressSpinner = true;
            const res = await http.post('/form/GetPostedFormScreenLink', {FormId: this.FormId, Email: Email});
            if(res.Success) {
                this.validInputCeNo = true;
                this.SendEmail = Email;
                this.$toast.add({severity:'success', summary: this.$i18n.tc("utils.sendMailSuccess"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            } else {
                this.$toast.add({severity:'error', summary: this.$i18n.tc("postedForm.sendMailFailed"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
            this.displayProgressSpinner = false;
        },
    }
}
</script>

<style scoped>
</style>

