<template>
    <template v-if="loading">
        <div class="progressSpinnerContainer">
            <ProgressSpinner  />
        </div>
    </template>
    <template v-else>
        <div class="p-d-flex  p-flex-column" style="width:100%; height: 100%; position: absolute;" v-if="Finished || BeforePublic" >
            <div class=" p-as-center" style="display: table;height:100%;">
                <div style="margin: 0 auto; display: table-cell;   vertical-align: middle;width: 100%; ">
                    <div class="">
                        <div>
                            <div class="p-text-center DocumentText" style="">
                                {{EndDocumentName}}
                            </div>
                            <div v-if="Finished" class="p-text-center loginLogoMini">{{$t('utils.finishedFormText')}}</div>
                            <div v-if="BeforePublic" class="p-text-center loginLogoMini">{{$t('utils.beforePublicFormText')}}</div>
                            <div class="p-text-center endDocumentId">
                                {{EndDocumentId}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BankInvoicePaperFooter />
        </div>
        <Panel class="notvisibility-header-panel inputFormPanel" v-else>
        <div class="p-grid p-jc-center">
            <div style="display: none;">
            {{MBFileSize}} MB
            </div>
            <div class="container width-100-phone" style="">
                <div class="savingContainer" v-if="docStatus == 1">
                    <div class="container savingWrapper p-d-flex" style="" v-if="getSavingData2LocalStorage==1" >
                        <div class="savingInner"><ProgressSpinner />  <span>{{$i18n.tc('inputForm.dataSaving')}}</span></div>
                    </div>
                    <div class="container savingWrapper p-d-flex" style="" v-else-if="getSavingData2LocalStorage==2" >
                        <div class="savingInner"><i class=" pi pi-cloud-upload" style="fontSize: 1rem; padding-right: 2px;" /><span>{{$i18n.tc('inputForm.dataSaved')}}</span></div>
                    </div>
                </div>

                <div style="margin-bottom: 10px;" class="p-d-flex">
                    <div>
                        <div v-if="publicFormatType == 'form' && docStatus == 1 && edit" style="display: inline-block; padding: 2px 10px; color: rgba(0, 0, 0, 0.26); border: 1px solid rgba(0, 0, 0, 0.26); white-space: nowrap;">
                            {{$t('inputForm.topText')}}
                        </div>

                        <div v-if="docStatus == 1 && !edit" style="display: inline-block; padding: 2px 10px; color: #e57373; border: 1px solid #e57373; white-space: nowrap;">
                            {{$t('inputForm.plsConfirm')}}
                        </div>
                    </div>
                    <div style="flex: 1;">
                    </div>
                    <div style="">
                        <Dropdown v-model="selectedLanguage" :options="languages" optionLabel="name" optionValue="value" class="selectLanguageInputFormDropdown" panelClass="selectLanguageInputFormDropdownPanel" />
                    </div>
                </div>
                <div v-if="docStatus == 1 " style="width: 100%; text-align: right;">
                    <Button :label="$t('inputForm.showQRcode')" class="p-button-secondary p-button-text" @click="onShowQRcodeClick" />
                    <OverlayPanel ref="op" class="qrcodeOverlayPanel" >
                        <!-- <VueQrcode :value="$constants.local+$route.fullPath" tag="img" class="cursor_pointer " :options="qrOption" ref="qrcode" @click="onQrcodeClick"  v-tooltip="$t('operationButtonBar.qrcodeTooltip')" /> -->
                        <VueQrcode :value="$constants.local+$route.fullPath" tag="img" class="cursor_pointer " :options="qrOption" ref="qrcode" @click="onQrcodeClick"  v-tooltip="$t('operationButtonBar.qrcodeTooltip')" />
                    </OverlayPanel>
                </div>
                <InlineMessage severity="info" v-if="!userAttrVal" class="p-col-12 " style="background-color: #f7f8fa; color: #495057;"> 
                {{$t('inputForm.selectUserAttrMessage')}} 
                </InlineMessage>
                <h1 class="p-col-12" style="text-align: left; font-size: 20px; font-weight: bold; padding-left: 8px; border-left: 4px solid #4a525a;" v-if="DocumentName">{{DocumentName}}</h1>
                <transition-group name="in-out-translate-fade" mode="out-in" key="transition-group1">
                    <div v-if="docStatus == 1" key="on" class="p-d-flex p-flex-column p-ac-stretch">
                        <div class="p-d-flex p-flex-column">
                            <div class="p-field" v-if="showUserAttr" >
                                <div class="p-mb-2" style="font-size: 16px;">
                                    {{$t('inputForm.select')}}
                                </div>
                                <div class="p-d-flex p-flex-column">
                                    <template v-for="(userAttr, attrIndex) in userAttrList" :key="attrIndex">
                                        <div class="p-d-flex p-jc-start p-field-radiobutton">
                                            <RadioButton :inputId="userAttr.value" :value="userAttr.value" v-model="userAttrVal" @change="onUserAttrChange"  :disabled="!edit"/>
                                            <label :for="userAttr.value" style="font-size: 16px;"> {{userAttr.label}}</label>
                                        </div>
                                    </template>
                                </div>
                            </div>

                            <div class="p-field p-grid" v-if="appTypeList.length">
                                <div class="p-formgroup-inline">
                                    <!-- <label>{{$t('inputForm.type')}}</label> -->
                                    <div v-for="type in appTypeList" class="p-field-checkbox" :key="type._id">
                                        <Checkbox :id="type.value" name="type2" :value="type.value" v-model="appType"  :disabled="!edit"/>
                                        <label :for="type.value" style="font-size: 16px;" > {{ type.label }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Dialog :visible="showUserAttr && !userAttrVal" modal="true" :header="$t('inputForm.select')">
                            <div class="p-d-flex p-flex-column p-ac-stretch">
                                    <div class=" p-field p-d-flex p-flex-column">
                                        <template v-for="(userAttr, attrIndex) in userAttrList" :key="attrIndex">
                                            <div class="p-d-flex p-jc-start p-field-radiobutton">
                                                <RadioButton :inputId="userAttr.value" :value="userAttr.value" v-model="userAttrVal" @change="onUserAttrChange"  :disabled="!edit"/>
                                                <label :for="userAttr.value" style="font-size: 16px;"> {{userAttr.label}}</label>
                                            </div>
                                        </template>
                                    </div>
                            </div>
                        </Dialog>

                        <transition name="in-out-translate-fade" mode="out-in"  key="transition1" appear>
                        <form v-if="form" class="p-d-flex p-flex-column" >
                            <template  v-for="(parts, index) in form"  :key="parts._id">
                                <formPanel :item="parts" :edit="edit" :required="requiredComponent(parts.Required, index)" >
                                <div class="p-col-12 align-item-center p-grid no_margin " :key="parts.Id" :class="{no_padding: parts.ComponentType=='Editor'}">
                                    <template v-for="(item, index_parts) in parts.items" :key="item.Id" >
                                        <component
                                                @setDataInItem="setDataToStore" 
                                                @autoSaveData="autoSaveData"
                                                v-bind:is="item.Xtype" 
                                                :item="item" 
                                                :Id="parts.Id"
                                                :edit="edit"
                                                :required="requiredComponent(parts.Required, index)"
                                                @clearRequireField="changeActiveRequiredField($event, index)"
                                                /> 
                                        <template v-if="parts.ComponentType != 'Editor' && item.Xtype != 'spaceField' && item.Xtype != 'simpleText'">
                                            <Button class="p-button-outlined p-button-info"  v-if="!edit && !parts.fix" @click="editFieldButtonClick($event, {item, index})">
                                                {{$t('inputForm.amend')}}
                                            </Button>
                                        <Button class="p-button-outlined p-button-danger"  v-if="!edit && parts.fix" @click="editFieldButtonClick($event, {item, index})">
                                            {{$t('inputForm.finish')}}
                                        </Button>
                                        </template>
                                    </template>
                                </div>
                                </formPanel>
                            </template>
                        </form>

                        </transition>
                        <div class="p-grid p-nogutter" v-if="docStatus == 1">
                            <div style="text-align: left; color: white;">
                                {{formTemplateId}}
                            </div>
                            <div class="p-col" />
                            <div style="text-align: right;">
                                <!-- <Button @click="importInputForm" :label="$t('inputForm.importFormButton')" class="p-button-secondary p-button-text" v-tooltip="$t('inputForm.importFormButtonTooltip')" /> -->
                                <Button type="button" icon="pi pi-ellipsis-h " class="p-button-rounded p-button-secondary p-button-text focus-box-shadow-none" @click="otherFormActionMenuToggle" aria-haspopup="true" aria-controls="overlay_menu" />
                                <Menu ref="otherFormActionMenu" id="overlay_menu" :model="otherFormActionMenu" :popup="true" />

                            </div>
                        </div>

                        <template v-if="publicFormatType == 'form'">
                        <div v-if="edit == true && userAttrVal" class="p-p-4">
                            <Button class="p-d-block p-mx-auto p-button-outlined p-button-info"  type="submit" @click.prevent="getFormValues()" v-bind:disabled="!userAttrVal"> {{$t('inputForm.toConfirmPanel')}} </Button>
                        </div>
                        <div v-else-if="edit == false" class="p-p-4">
                            <div class="p-d-block p-mx-auto text-center">
                                <Button class="p-mr-2 p-mb-2 p-button-outlined p-button-info"  v-bind:disabled="isDisabledSend" @click="changeDocStatus(0)"  > {{$t('inputForm.back')}} </Button>
                                <Button class="p-mr-2 p-mb-2 p-button-outlined p-button-info"  v-bind:disabled="isDisabledSend" @click="changeDocStatus(2)" > {{$t('inputForm.Send')}} </Button>
                            </div>
                            <div v-if="isDisabledSend"  class="p-d-block p-mx-auto warning-color text-center">
                                {{$t('inputForm.cantSendAmend')}}
                            </div>
                        </div>
                        </template>
                    </div>
                    <div v-else-if="docStatus == 2" key="second" class="p-d-flex p-flex-column p-ac-stretch">
                        <div class="p-d-flex p-flex-column text-center">
                            <template v-if="displaySendFormStatus == 0">
                                <div class="p-field">
                                    <span style="font-size: 1.6rem; font-weight: normal; ">{{$t('inputForm.formStatus_Sending')}}</span>
                                </div>
                            </template>
                            <template v-else-if="displaySendFormStatus == 1">
                                <div class="" style="margin-bottom: 30px; margin-top: 20px;">
                                    <i class=" pi pi-check-circle" style="fontSize: 3rem; color: #0288D1"></i>
                                </div>
                                <div class="p-field">
                                    <span style="font-size: 1.6rem; font-weight: normal; ">{{$t('inputForm.formStatus_End')}}</span> <br />
                                    <label style="font-size: 12px;" v-if="isExistUser" >{{$t('inputForm.sendEmail2ExistUser')}}</label>
                                </div>
                                <div class="p-field MessageAfterSendingFormContainer" v-if="MessageAfterSendingForm" :class="{'MessageAfterSendingFormContainerMobile': isMobile.isMobile()}">
                                    <FormMessageEditor class="formMessageEditorAfterMessageInfo formMessageEditorMessageInfoInAfterFormMessage" :content="MessageAfterSendingForm" :disabled="true" />
                                </div>
                                <div class="container doorContainer p-field" :class="{'MessageAfterSendingFormContainerMobile': isMobile.isMobile()}" >
                                    <div style="" class=" p-grid p-nogutter">
                                        <label style="font-size: 12px;" v-if="isExistUser" >{{$t('inputForm.inputDescriptionCopyEmailAgain')}}</label>
                                        <label style="font-size: 12px;" v-else >{{$t('inputForm.inputDescriptionCopyEmail')}}</label>
                                    </div>
                                    <div class="p-grid">
                                        <InputText 
                                        style="min-width: 240px"
                                        class="p-col"
                                        v-model="descriptionCopyEmail" 
                                        :placeholder="$t('inputForm.descriptionCopyEmailPlaceholder')"
                                        />
                                        <LoadingButton 
                                        type="button"
                                        style="font-size: 14px;"
                                        class="p-button-outlined p-button-info" 
                                        @click="onSendDescriptionCopyEmailButtonClick" 
                                        :Text="$t('inputForm.Send')"
                                        :LoadDone="$t('inputForm.SendComplate')"
                                        :ButtonLoadingStatus="descriptionCopyEmailButtonStatus"
                                        /> 
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="displaySendFormStatus == 2">
                                <div class="" style="margin-bottom: 30px; margin-top: 20px;">
                                    <i class="pi pi-exclamation-triangle" style="fontSize: 3rem; color: #f44336"></i>
                                </div>
                                <div class="p-field">
                                    <span style="font-size: 1.6rem; font-weight: normal; ">{{$t('inputForm.formStatus_Failed')}}</span>
                                </div>
                            </template>
                        </div>
                        <template v-if="displayProgressSpinner">
                            <div class="progressSpinnerContainer">
                                <ProgressSpinner />
                            </div>
                        </template>
                    </div>

                    <div class="p-grid p-nogutter" :class="{'importInputFormContaianerMobile': isMobile.isMobile()}" v-if="docStatus == 2">
                        <div style="text-align: left; color: white;">
                            {{formTemplateId}}
                        </div>
                        <div class="p-col" />
                        <div style="text-align: right;">
                            <!-- <Button @click="importInputForm" :label="$t('inputForm.importFormButton')" class="p-button-secondary p-button-text" v-tooltip="$t('inputForm.importFormButtonTooltip')" /> -->
                            <Button type="button" icon="pi pi-ellipsis-h " class="p-button-rounded p-button-secondary p-button-text focus-box-shadow-none" @click="otherFormActionMenuToggle" aria-haspopup="true" aria-controls="overlay_menu" />
                            <Menu ref="otherFormActionMenu" id="overlay_menu" :model="otherFormActionMenu" :popup="true" />

                        </div>
                    </div>
                </transition-group>
                <InputViewFooter :publicFormatType="publicFormatType" :AuthorName="AuthorName" />
            </div>
        </div>
        </Panel>
        <Dialog :header="DialogHeaderMessage" v-model:visible="displayMessageDialog">
            <div class="p-grid no_margin">
                <template v-for="message in DialogMessageList">
                    <div class="p-col-12" style="text-align: left"  >
                        <span style="word-break: break-word; white-space: pre-line;"> {{message}} </span>
                    </div>
                </template>
                <div class="p-col-12" style="text-align: right;"  >
                    <!-- <Button  class="p-button-outlined p-button-info" @click="onCloseRetryButtonClick" > {{$t("utils.cancel")}} </Button> -->
                    <Button  class="p-button-outlined p-button-info" @click="onRetryButtonClick" > {{$t("utils.retry")}} </Button>
                </div>
            </div>
        </Dialog>
    </template>

</template>

<script>
// @ is an alias to /src
import http from '../mixins/api'
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import Calendar from 'primevue/calendar';
import formPanel from '@/components/inputFormParts/formPanel'
import textField from '@/components/inputFormParts/textField'
import spaceField from '@/components/templateFormParts/spaceField'
import textAreaField from '@/components/inputFormParts/textAreaField'
import dropDownField from '@/components/inputFormParts/dropDownField'
import multiSelectField from '@/components/inputFormParts/multiSelectField'
import simpleText from '@/components/inputFormParts/simpleText';
import dateField from '@/components/inputFormParts/dateField'
import timeField from '@/components/inputFormParts/timeField';
import datetimeField from '@/components/inputFormParts/datetimeField';
import fileField from '@/components/inputFormParts/fileField'
import radioBoxGroupField from '@/components/inputFormParts/radioBoxGroupField'
import checkBoxGroupField from '@/components/inputFormParts/checkBoxGroupField'
import fullEditor from '@/components/inputFormParts/fullEditor'
import fullEditor4Applicant from '@/components/inputFormParts/fullEditor4Applicant';
import ProgressSpinner from 'primevue/progressspinner';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Card from 'primevue/card';

import Panel from 'primevue/panel';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import BankInvoicePaperFooter from '@/components/BankInvoicePaperFooter';
import InputViewFooter from '@/components/InputViewFooter';
import isMobile from '@/mixins/device'
import LoadingButton from '@/components/LoadingButton'
import OverlayPanel from 'primevue/overlaypanel';
import VueQrcode from "@chenfengyuan/vue-qrcode";
import Tooltip from 'primevue/tooltip'
import InlineMessage from 'primevue/inlinemessage';
import Menu from 'primevue/menu';
import FormMessageEditor from '@/components/formMessageEditor';


import store from '@/store'
import helper from '@/mixins/helper';



export default {
    name: 'InputForm',
    components: {
        formPanel,
        dropDownField,
        multiSelectField,
        RadioButton,
        Checkbox,
        Calendar,
        Panel,
        textField,
        spaceField,
        textAreaField,
        simpleText,
        dateField,
        timeField,
        datetimeField,
        fileField,
        radioBoxGroupField,
        checkBoxGroupField,
        fullEditor,
        fullEditor4Applicant,
        Button,
        ProgressSpinner,
        BankInvoicePaperFooter,
        InputViewFooter,
        Dialog,
        InputText,
        Textarea,
        Card,
        LoadingButton,
        OverlayPanel,
        VueQrcode,
        InlineMessage,
        Menu,
        FormMessageEditor,
        // PanelComponent,
        // FormComponent,
    },
    data: function(){
        return{
            data:{
                form: {},
                formList: [],
                userAttrList: [],
                appTypeList: [],
            },
            loading: true,
            // appType: [],
            showUserAttr: true,
            docStatus: 1,
            edit: true,
            displaySendFormStatus: 0,
            displayProgressSpinner: true,
            activeRequiredFieldIndexList: {},
            hasRedirectFlag: false,
            skipEmailAuth: false,
            publicFormatType: "form",
            Finished: false,
            BeforePublic: false,
            EndDocumentName: "",
            EndDocumentId: "",
            displayMessageDialog: false,
            DialogHeaderMessage: "",
            DialogMessageList: [],
            DialogMessage: "",
            formId: "",
            isExistUser: false,
            MessageAfterSendingForm: "",
            descriptionCopyEmailButtonStatus: 0,
            qrOption: {
                width: 140,
            },
            isMobile,
            AuthorName: "",
            otherFormActionMenu: [
                {
                    label: this.$i18n.tc('inputForm.importFormButton'),
                    command: (e) => {
                        this.importInputForm(e)
                    }
                }
            ],
        }
    },
    watch: {
        edit: function() {
            window.scrollTo(0, 0)
        },
    },
    computed: {
        DocumentName: {
            get: function() {
                return this.$store.getters["inputForm/getDocumentName"]
            }
        },
        form: {
            cache: false,
            get: function() {
                const activeForm = this.$store.getters["inputForm/getActiveForm"]
                return activeForm
            }
        },
        userAttrList: {
            get: function() {
                return this.$store.getters["inputForm/getUserAttrList"]
            }
        },
        appTypeList: {
            get: function() {
                return this.$store.getters["inputForm/getActiveAppTypeList"]
            },
        },
        appType: {
            get: function() {
                return this.$store.getters["inputForm/getAppType"] || []
            },
            set: function(value) {
                const list = []
                Object.keys(value).forEach(function (key) {
                    list.push(value[key])
                });
                this.$store.commit('inputForm/setAppType', value)
                this.$store.commit('inputForm/savingUserAttrAndAppType2LocalStorage')
            }
        },
        userAttrVal: {
            get: function() {
                const activeUserAttr = this.$store.getters["inputForm/getActiveUserAttr"]
                return activeUserAttr
            },
            set: function(value) {
                this.$store.commit('inputForm/setActiveUserAttr', value)
                this.$store.commit('inputForm/savingUserAttrAndAppType2LocalStorage')
            }
        },
        isDisabledSend: {
            get: function() {
                const index = this.$store.getters["inputForm/getActiveForm"].findIndex(x => x.fix)
                return index != -1
            }
        },
        requiredComponent: {
            get: function() {
                const self = this;
                return function(required, index) {
                    if(required == "true") {
                        if(self.activeRequiredFieldIndexList[index]) {
                            return "false"
                        }
                        return "true"
                    }
                    return "false"
                }
            },
        },
        MBFileSize: {
            get: function() {
                const MAXMB = this.$store.getters["inputForm/getMaxMBFileSize"]
                const MB = this.$store.getters["inputForm/getMBFileSize"]
                if(MAXMB < MB) {
                    this.$toast.add({severity:'error', detail: this.$i18n.tc("inputForm.exceededMaxFileStorage", {MB: MAXMB}), life: this.$constants.warningHelperLife, group: this.$constants.defaultToastGroup});
                }
                return MB
            },
        },
        descriptionCopyEmail: {
            get: function() {
                return this.$store.getters["inputForm/getDescriptionCopyEmail"]
            },
            set: function(value) {
                this.$store.commit("inputForm/setDescriptionCopyEmail", value)
            }
        },
        getSavingData2LocalStorage: {
            get: function() {
                return this.$store.getters["inputForm/getSavingData2LocalStorage"]
            },
        },
        selectedLanguage: {
            get: function() {
                const userLanguage = this.$store.getters["applicantStore/getUserLanguage"]
                this.$primevue.config.locale.today = this.$i18n.tc("locale.today");
                this.$primevue.config.locale.now = this.$i18n.tc("locale.now");
                this.$primevue.config.locale.save = this.$i18n.tc("locale.save");
                this.$primevue.config.locale.clear = this.$i18n.tc("locale.clear");

                return userLanguage
            },
            set: function(value) {
                this.$store.commit("applicantStore/setUserLanguage", value)
                this.$store.commit('inputForm/setUserAttrData', value)
            },
        },

        languages: {
            get: function() {
                return this.$store.getters["applicantStore/getUserLanguageList"]
            },
        },

        formTemplateId: {
            get: function() {
                const formTemplateId = this.$store.getters['inputForm/getFormTemplateId']
                return formTemplateId
            }
        }
    },

    created() {
        const self = this 
        // const alertHandler = () => {
        //     alert(self.$i18n.tc("utils.backInInputForm"));
        //     history.go(1)
        // }
        // window.removeEventListener('popstate', alertHandler);
        // window.addEventListener('popstate', alertHandler);
        window.addEventListener('beforeunload', function(e) {
            e.returnValue = self.$i18n.tc("utils.backInInputForm");
        });
    },

    async beforeRouteEnter(route, redirect, next) {
        const query = route.query
        const userId = query.userId
        const id = query.id
        let skipEmailAuth = query.skipEmailAuth ? query.skipEmailAuth == "true" : false

        const DepartmentUUID = route.params.departmentUUID
        const LinkUUID = route.params.linkUUID

        if(id) {
            const res_url = await http.post("/form/GetFormURLByTemplateIdInDoor", {id: id, skipEmailAuth: skipEmailAuth, DepartmentUUID: DepartmentUUID, LinkUUID: LinkUUID }, {}, false, false)
            if(res_url.Success) {
                if(route.path != res_url.Path) {
                    next(res_url.Path)
                    return
                }
            } else {
                next(false)
                return
            }
        }

        const isPassedBeforeForm = store.getters['applicantStore/isPassedBeforeForm']
        if (!isPassedBeforeForm) {
            const res_passed = await http.post("/form/checkFormTemplatePassword", {DepartmentUUID: DepartmentUUID, LinkUUID: LinkUUID}, {}, false, false)
            if (res_passed.Success) {
                if(res_passed.NonePassword) {
                    store.commit('applicantStore/setIsPassedBeforeForm', true)
                } else {
                    next({name: "form_validation_doors", params: {departmentUUID: DepartmentUUID, linkUUID: LinkUUID}})
                    return 
                }
            } else {
                next({path: "/notfoundforms", query: {link: location.href}})
                return
            }
        }

        const isPassedBeforeFormMessage = store.getters['applicantStore/isPassedBeforeFormMessage']
        if (!isPassedBeforeFormMessage) {
            const res_passed = await http.post("/form/checkFormTemplatePreFormMessage", {DepartmentUUID: DepartmentUUID, LinkUUID: LinkUUID}, {}, false, false)
            if (res_passed.Success) {
                if(res_passed.IsActive) {
                    next({name: "form_pre_message", params: {departmentUUID: DepartmentUUID, linkUUID: LinkUUID}})
                    return 
                } else {
                    store.commit('applicantStore/setIsPassedBeforeFormMessage', true)
                }
            } else {
                next({path: "/notfoundforms", query: {link: location.href}})
                return
            }
        }


        // if(!skipEmailAuth) {
        //     if(!(localStorage.formUserToken && localStorage.formUserInfo)) {
        //         next(vm => vm.failedSetData(id))
        //         return
        //     }
        // }
        // history.pushState(null, null, location.href);

        next(vm => vm.getData({id: id, skipEmailAuth: skipEmailAuth, DepartmentUUID: DepartmentUUID, LinkUUID: LinkUUID}))

    },

    beforeRouteLeave (to, from, next) {
        if(!this.hasRedirectFlag) {
            const answer = window.confirm(this.$i18n.tc("inputForm.notSaved"))
            if (answer) {
                next()
            } else {
                next(false)
            }
        } else {
            next()
        }
    },

    methods: {
        getData: async function(obj) {
            const id = obj.id
            let skipEmailAuth = obj.skipEmailAuth
            const DepartmentUUID = obj.DepartmentUUID
            const LinkUUID = obj.LinkUUID
            const res = await http.post("/form/GetInputForm", {id: id, DepartmentUUID: DepartmentUUID, LinkUUID: LinkUUID }, {}, false, false)
            if(res.Success) {
                skipEmailAuth = this.$route.query.skipEmailAuth ? this.$route.query.skipEmailAuth == "true" : res.skipEmailAuth
                this.publicFormatType = res.publicFormatType
                if(!skipEmailAuth) {
                    if(!(localStorage.formUserToken && localStorage.formUserInfo)) {
                        this.hasRedirectFlag = true
                        this.$router.replace({ path: '/doors/' + DepartmentUUID + "/" + LinkUUID });
                    }
                }


                store.commit('inputForm/setFormTemplateId', res.id)
                this.setData({...res, skipEmailAuth: skipEmailAuth})
            } else if(res.notPubliced) {
                this.wrongPage(res.DocumentName, res.id, res.isBefore)
            } else {
                this.failedSetData(id)
            }
            this.loading = false
        },

        wrongPage: function(DocumentName, TemplateId, isBefore) {
            this.EndDocumentName = DocumentName
            if (TemplateId) {
                let EndDocumentId = ""
                for (let i = 0; i < TemplateId.length; i++) {
                    if (i < 4 || i >= TemplateId.length - 4) {
                        EndDocumentId += TemplateId[i]
                    } else if (i < 7) {
                        EndDocumentId += "."
                    }
                }
                this.EndDocumentId = EndDocumentId
            }
            if (isBefore) {
                this.BeforePublic = true
            } else {
                this.Finished = true
            }
        },
        failedSetData(id) {
            localStorage.removeItem('formUserToken')
            localStorage.removeItem('formUserInfo')
            this.$toast.add({severity:'error', detail: this.$i18n.tc("utils.failed"), detail: this.$i18n.tc("inputForm.backToDoor"), life: this.$constants.warningHelperLife, group: this.$constants.defaultToastGroup});
            this.hasRedirectFlag = true
            // this.$router.push({ path: '/notfoundforms' });
            this.$router.push({path: "/notfoundforms", query: {link: location.href}})
        },
        setData (data) {
            this.$store.commit("applicantStore/setInitUserLanguage")
            this.$store.commit('inputForm/setAllUserAttrData', data.allUserAttrList)
            this.$store.commit('inputForm/setUserAttrData', this.selectedLanguage)
            this.$store.commit('inputForm/setAppTypeData', data.appTypeList)
            this.$store.commit('inputForm/setFormListData', data.formList)
            this.$store.commit('inputForm/setTemplateInfo', data.templateInfo)
            this.$store.commit('inputForm/setMaxMBFileSize', data.MaxMBFileSize)
            if(data.templateInfo.ScopeOfPublication != "kojin_houjin") {
                this.$store.commit('inputForm/setActiveUserAttr', data.templateInfo.ScopeOfPublication)
                this.userAttrVal = data.templateInfo.ScopeOfPublication
                this.showUserAttr = false
            }
            if(data.AuthorName) {
                this.AuthorName = data.AuthorName
            }
            this.skipEmailAuth = data.skipEmailAuth
            this.docStatus = 1;
            window.scrollTo(0, 0)

            const inputtingDataList = localStorage.inputtingDataList ? JSON.parse(localStorage.inputtingDataList) : []
            const formTemplateId = this.$store.getters['inputForm/getFormTemplateId']
            const existIndex = inputtingDataList.findIndex(x => x.formTemplateId == formTemplateId)
            if(existIndex != -1) {
                this.$store.commit('inputForm/setInitFormData', inputtingDataList[existIndex])
                this.$store.commit('inputForm/setActiveUserAttr', inputtingDataList[existIndex].activeUserAttr)
                

            }
        },

        async getFormValues() {
            const editindDataList = this.$store.getters['inputForm/getEditindDataList']
            this.$store.commit('inputForm/formUpdate', editindDataList)
            const ok = await this.checkBlackField()
            if(ok) {
                this.edit = false;
                // for (let i = 0; i < editindDataList.length; i++) {
                //     let data = editindDataList[i]
                //     this.$store.commit('inputForm/formUpdate', editindDataList)
                // }


            }
        },

        async changeDocStatus(nextDocStatus) {
            if(nextDocStatus == 1) {
                this.edit = false
            } else if(nextDocStatus == 0) {
                this.edit = true
                this.$store.commit("inputForm/clearFixingDataInItem")
                this.onUserAttrChange()
                
            } else if(nextDocStatus == 2) {
                const ok = await this.checkBlackField()
                if(ok) {
                    this.docStatus = 2;

                    const language = this.selectedLanguage
                    const browserTimezoneOffset = (new Date()).getTimezoneOffset()
                    const res = await store.dispatch("inputForm/sendFormData", {skipEmailAuth: this.skipEmailAuth, DepartmentUUID: this.$route.params.departmentUUID, LinkUUID: this.$route.params.linkUUID, language: language, browserTimezoneOffset: browserTimezoneOffset})
                    if(res && res.Success) {
                        const formTemplateId = this.$store.getters['inputForm/getFormTemplateId']
                        this.$toast.add({severity:'success', summary: this.$i18n.tc("inputForm.formStatus_End"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                        
                        this.$store.commit('inputForm/clearInitFormData', formTemplateId)
                        this.$store.commit('inputForm/setDescriptionCopyEmail', res.DescriptionCopyEmail)
                        this.formId = res.FormId
                        this.displaySendFormStatus = 1
                        localStorage.removeItem('formUserToken')
                        localStorage.removeItem('formUserInfo')

                        this.isExistUser = res.isExistUser
                        this.MessageAfterSendingForm = res.MessageAfterSendingForm
                    } else if(res.isPublicFailed){
                        if (res.isBefore) {
                            this.BeforePublic = true
                        } else {
                            this.Finished = true
                        }
                    } else {
                        this.displaySendFormStatus = 2
                        if(res.Message || res.MessageList) {
                            this.DialogMessageList = res.MessageList ? res.MessageList : [res.Message]
                            this.displayMessageDialog = true
                            this.DialogHeaderMessage = this.$i18n.tc("inputForm.formStatus_Failed")
                        }
                        // this.$toast.add({severity:'error', summary: this.$i18n.tc("inputForm.formStatus_Failed"), life: this.$constants.warningHelperLife, group: this.$constants.defaultToastGroup});
                    }
                    this.displayProgressSpinner = false
                }
            }
        },

        async checkBlackField() {
            const blank = await this.$store.dispatch('inputForm/hasBlankInRequired')
            if(blank) {
                const self = this;
                return new Promise(function(resolve, reject) {
                    self.$confirm.require({
                        message: self.$i18n.tc("inputForm.isForward"),
                        autoFocus: "reject",
                        accept: async function() {
                            resolve(true)
                        },
                        reject: () => {
                            resolve(false)
                        }
                    })
                })
            } else {
                return true
            }
        },

        onUserAttrChange(e) {
            if(e && e.target && e.target.value)
                this.$store.commit('inputForm/setActiveUserAttr', e.target.value)
            window.scrollTo(0, 0)
        },

        setDataToStore: function(data) {
            this.$store.commit('inputForm/formUpdate', [data])
        },

        autoSaveData: function(data) {
            const num = Math.floor(Math.random() * 1000) + 1
            this.setSavingData2LocalStorage(num, data)
        },

        setSavingData2LocalStorage: function(num, data) {
            const self = this;
            this.$store.commit('inputForm/setSavingData2LocalStorageNumber', num)
            self.$store.commit('inputForm/storeAutoSaveData', data)
            setTimeout(() => {
                const storageNum = self.$store.getters['inputForm/getSavingData2LocalStorageNumber']
                if(storageNum == num) {
                    self.$store.commit('inputForm/setSavingData2LocalStorage', 1)
                    self.$store.commit('inputForm/savingData2LocalStorage')
                }
                setTimeout(() => {
                    const storageNum2 = self.$store.getters['inputForm/getSavingData2LocalStorageNumber']
                    self.$store.commit('inputForm/setSavingData2LocalStorage', 2)
                    if(storageNum2 == num) {
                        setTimeout(() => {
                            self.$store.commit('inputForm/setSavingData2LocalStorage', 0)
                        }, 2000)
                    }
                }, 2000)
            }, 2000)
        },

        editFieldButtonClick: function(e, obj) {
            this.$store.commit('inputForm/setFixField', {item: obj.item, index: obj.index})
        },

        changeActiveRequiredField: function(e, index) {
            this.activeRequiredFieldIndexList[index] = e
            // if(e ) {
            //     let requiredIndex = this.activeRequiredFieldIndexList.findIndex(x => x == index)
            //     if(requiredIndex == -1) {
            //         this.activeRequiredFieldIndexList.push(requiredIndex)
            //     }
            // } else {
            //     let requiredIndex = this.activeRequiredFieldIndexList.findIndex(x => x == index)
            //     if(requiredIndex != -1) {
            //         this.activeRequiredFieldIndexList.splice(requiredIndex, 1)
            //     }
            // }
        },

        onRetryButtonClick: function(e) {
            this.displayMessageDialog = false
            this.displaySendFormStatus = 0
            this.docStatus = 1;
            this.changeDocStatus(1)
            this.displayProgressSpinner = true
        },

        onCloseRetryButtonClick: function() {
            this.displayMessageDialog = false
            localStorage.removeItem('formUserToken')
            localStorage.removeItem('formUserInfo')
        },

        onSendDescriptionCopyEmailButtonClick: async function() {
            await (this.descriptionCopyEmailButtonStatus = 2)
            const descriptionCopyEmail = this.descriptionCopyEmail
            const formId = this.formId
            const res = await http.post("/form/SendDescriptionCopyEmail", {descriptionCopyEmail: descriptionCopyEmail, FormId: formId}, {}, false, false)
            if(res.Success) {
                this.$toast.add({severity:'success', summary: this.$i18n.tc("inputForm.formStatus_End"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            } else {
                this.$toast.add({severity: 'error', detail: res.Message, life: this.$constants.warningHelperLife, group: this.$constants.defaultToastGroup});
            }
            this.$store.commit("inputForm/setDescriptionCopyEmail", "")
            this.descriptionCopyEmailButtonStatus = 1
        },

        onShowQRcodeClick: function(e) {
            this.$refs.op.toggle(e)
        },

        otherFormActionMenuToggle(event) {
            this.$refs.otherFormActionMenu.toggle(event);
        },

        onQrcodeClick: function() {
            const src = this.$refs.qrcode.$el.src
            helper.copyImage64ToClipboard(src)
            this.$toast.add({severity:'success', summary: this.$i18n.tc("operationButtonBar.copiedQrcode"), life: this.$constants.successHelperLife, group:  this.$constants.defaultToastGroup});
        },

        importInputForm: function() {
            const href = encodeURIComponent(location.href)
            let resolvedRoute = this.$router.resolve({
                name: "draft",
                params: {departmentId: this.$constants.AllDepartmentIdConstants},
                query: {importFormUrl: href}
            });
            window.open(resolvedRoute.href, '_blank');

        }
    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>

<style scoped>
.endDocumentId {
    font-size: 12px;
    font-weight: normal;
    color: #666666;
    padding-bottom: 0px;
    font-family: 'MS P明朝', courier,'SF Pro Icons','Helvetica Neue',Helvetica,Arial,sans-serif;
}
.loginLogoMini {
    font-size: 20px;
    font-weight: normal;
    color: #666666;
    padding-bottom: 0px;
    font-family: 'MS P明朝', courier,'SF Pro Icons','Helvetica Neue',Helvetica,Arial,sans-serif;
}
.DocumentText {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.savingContainer {
    height: 20px;
    position: fixed;
    z-index: 2;
    pointer-events:none;
}
.savingWrapper {
    position: absolute;
    top: 10px;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .3px;
    line-height: 16px;
    color: #5f6368;
}
.savingWrapper .p-progress-spinner {
    width: 20px;
    height: 20px;
    right: 4px;
}
.savingInner {
    position: absolute;
    padding-right: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
}

.MessageAfterSendingFormContainer {
    /* margin-top: 70px; */
    margin: 70px auto 10px auto;
    width: 40vw;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
.MessageAfterSendingFormContainerMobile {
    margin: 10px auto 0px auto;
    width: 100%;
    padding-left: 0px;
    padding-right: 0px;
}
.MessageAfterSendingFormContainer textarea,
.MessageAfterSendingFormContainer textarea:focus {
    min-height: 103px;
    width: 100%; 
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 3px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    border: none;
    border-radius: 6px;
}
.MessageAfterSendingFormContainerMobile textarea,
.MessageAfterSendingFormContainerMobile textarea:focus {
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 3px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    width: 100%; 
    min-height: 103px;
    border: none;
    border-radius: 6px;
}
.importInputFormContaianerMobile {
    margin-top: 40px;
}
</style>
