<template>
    <div class="p-p-4">
        <div class="p-grid p-jc-center " >
            <div class="p-col-9 p-md-9 p-lg-9 p-grid p-nogutter">
                <div class="p-col-12 p-grid p-jc-center">
                    <div class="p-col-8 p-field p-grid" >
                        <h4 class="" style="color: black; padding: 0.5rem;" >{{$t("changeEmail.title")}}</h4>
                    </div>
                </div>
                <div class="p-col-12 p-grid p-jc-center p-as-center">
                    <div class="p-col-8 p-field p-grid" style="margin-bottom: 0.1rem;">
                        <span class="p-col-fixed input_label" style="justify-content: right;" >{{$t("changeEmail.Email")}}</span>
                        <InputText class="p-col" v-model.trim="Email" />
                    </div>
                </div>
                <div class="p-col-12 p-grid p-jc-center">
                    <div class="p-col-8 p-grid p-field" style="margin-bottom: 0.1rem;">
                        <span class="p-col-fixed input_label" style="justify-content: right;">{{$t("changeEmail.NextEmail")}}</span>
                        <InputText class="p-col" v-model.trim="nextEmail"  />
                    </div>
                </div>
                <div class="p-col-12 p-grid  p-jc-center" style="">
                    <div class="p-col-8 p-grid p-field" style="justify-content: flex-end; margin-bottom: 0px;">
                        <Button class="p-button-outlined p-button-info" @click="onChangeEmailClick">{{$t("changeEmail.change")}}</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import http from '../mixins/api'
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

export default {
    name: 'ChangeWorkerEmail',
    components: {
        Button,
        InputText,
    },
    data() {
        return {
            UUID: "",
            UserId: "",
            Email: "",
            nextEmail: "",
        }
    },
    async beforeRouteEnter(route, redirect, next) {
        const query = route.query
        const UUID = query.UUID
        const UserId = query.USERID
        next(vm => vm.setData({
            UUID : UUID,
            UserId : UserId
        }))
    },
    created() {
    },
    mounted() {
    },
    computed: {
    },
    methods: {
        setData: function(data) {
            this.UUID = data.UUID
            this.UserId = data.UserId
        },
        onChangeEmailClick: async function() {
            const res = await http.post("/user/ChangeEmail", {UUID: this.UUID, UserId: this.UserId, Email: this.Email, NextEmail: this.nextEmail})
            if(res.Success) {
                this.$toast.add({severity:'success', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.$store.commit("auth/logout") 
                this.$store.commit("auth/login", {userInfo: res.userInfo, token: res.token})
                this.$router.push('/admin_login')

            } else {
                this.$toast.add({severity:'error', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
        },
    }
}
</script>

