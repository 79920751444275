<template>
    <Dialog v-model:visible="displayDialog" :header="title" contentStyle="position: relative; "  :style="{width: '400px'}">
        <div class="p-grid no_margin">
            <template v-for="textObj in textList">
                <div class="p-col-12" style="text-align: left"  >
                    <span style="word-break: break-word; white-space: pre-line;"> {{textObj.text}} </span>
                </div>
            </template>
        </div>
        <template #footer>
                <div class="p-grid">
                    <div class="p-col text-left">
                        <Button  style="" class="p-button-outlined p-button-info" @click="displayDialog = false" > {{$t("utils.later")}} </Button>
                    </div>
                    <div class="p-col text-right">
                        <UpgradeButton />
                    </div>
                </div>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import RadioButton from 'primevue/radiobutton';
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import http from '../mixins/api'
import UpgradeButton from "@/components/upgradeButton"

export default {
    name: 'UpgradePopup',
    emits: ['updateDisplayDialog'],
    components: {
        Dialog,
        Button,
        UpgradeButton,
    },
    data: function() {
        return {
        }
    },
    created() {
    },
    computed: {
        displayDialog:  {
            get: function() {
                const value = this.$store.getters["upgradePopup/getShow"]
                this.$emit("updateDisplayDialog", value)
                return value
            },
            set: function(value) {
                this.$store.commit("upgradePopup/setShow", value)
            },
        },
        title: {
            get: function() {
                return this.$store.getters["upgradePopup/getTitle"]
            }
        },
        textList: {
            get: function() {
                return this.$store.getters["upgradePopup/getTextList"]
            }
        }
    },
    watch: {
    },
    methods: {
        toUpgradeButtonClick: function() {
            const DepartmentList = this.$store.getters["userBranchInfo/getBelongDepartmentList"]
            if(DepartmentList.length) {
                const departmentId = DepartmentList[0]._id
                this.$router.push({ path: `/plans/info/${departmentId}`});
            }
            this.displayDialog = false
        }
    },
}
</script>

<style scoped>
</style>
