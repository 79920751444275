<template>
    <div class="home">
        <input v-model="Email" />
        <input v-model="Password" />
        <button @click="login"> login </button>
    </div>
</template>

<script>
import axios from 'axios'
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import http from '@/mixins/api'

export default {
    name: 'Home',
    components: {
        HelloWorld
    },
    data: function() {
        return {
            Email: "",
            Password: "",
        }
    },

    methods: {
        async login() {
            const res = await http.post("/user/LoginWorkUser", {Email: this.Email, Password: this.Password})
            if(res.Success) {
                localStorage.jwt = res.token
                this.$router.push('/admin')
            }
        }
    }
}
</script>

