<template>
    <div class="p-d-flex p-flex-column">
        <div class=" p-mt-2" style="padding: 0.5rem; width: 100%;">
            <span style="white-space: pre-line; word-break: break-word;">{{$t('menuPanelConfigPanel.info')}}</span>
            <i v-tooltip="$t('menuPanelConfigPanel.subfolderInfo')" class="pi pi-info-circle p-pl-1"></i>
        </div>
        <div class="p-col p-d-flex p-flex-column customScrollPanel" style="max-height: 500px ; position: relative;height: auto; width:100%;">
            <ScrollPanel class="customScrollPanel " style="position: relative;height: 100%; width:100%;">
                <template v-for="(EditableRoute, routeIndex) in EditableRouteList">
                    <div class="p-col-12 p-grid" style="margin-left: 0px; margin-right: 0px;">
                        <div class="p-col-12 no_margin p-grid">
                            <label style="font-weight: bold;font-size: 16px;"> {{EditableRoute.label }}</label>
                            <i class="pi pi-plus-circle text-right p-ml-2 p-pt-1" @click="onPlusDetailStatusClick($event, item, routeIndex)" v-tooltip="$t('menuPanelConfigPanel.plusIconInfo')" />
                        </div>
                        <!-- <span class="defaultLabel" style=""> {{EditableRoute.items[0].label}}</span> -->
                        <div class="list-group p-col-12  no_padding_side" style=" padding-bottom: 0px;"> 
                            <div class="p-col-12 p-grid p-ml-1" style="padding-left: 34px; padding-right: 0px; padding-bottom: 0px;" v-if="index != 0" disabled >
                                <InputText type="text" class="p-col" style="min-width: 80px; background-color: lightgray;" :value="EditableRoute.items[0].label" disabled  />
                                <i class="pi pi-sort-alt handle cursor_pointer p-col-fixed p-disabled" style="align-self: center;" v-tooltip="$t('menuPanelConfigPanel.moveIconInfo')" disabled></i>
                                <span class=" cursor_pointer p-ml-1 p-mr-1 material-symbols-outlined p-disabled" style="align-self: center;"  v-tooltip="$t('menuPanelConfigPanel.deleteIconInfo')" disabled> delete_forever </span>
                                <SelectButton  
                                                                                                                                   :modelValue="true"
                                              :options="isVisibleDetailStatus" 
                                              optionLabel="label" 
                                              optionValue="value" 
                                              class="p-col-fixed" 
                                              style="vertical-align: middle; width: 150px;" 
                                              disabled
                                              />
                            </div>
                        </div>


                        <draggable
                            tag="div"
                            :list="EditableRoute.items"
                            class="list-group p-col-12  no_padding_side"
                            handle=".handle"
                            item-key="_id">
                            <template #item="{ element, index }">
                                <div class="p-col-12 p-grid p-ml-1" style="padding-left: 34px; padding-right: 0px;" v-if="index != 0">
                                    <InputText type="text" class="p-col" style="min-width: 80px;" :value="element.label" @input="onInputDetailStatusLabel($event, routeIndex, index)" />
                                    <i class="pi pi-sort-alt handle cursor_pointer p-col-fixed" style="align-self: center;" v-tooltip="$t('menuPanelConfigPanel.moveIconInfo')"></i>
                                    <span class=" cursor_pointer p-ml-1 p-mr-1 material-symbols-outlined" style="align-self: center;" @click="onDeleteDetailStatusClick($event, element, routeIndex, index)" v-tooltip="$t('menuPanelConfigPanel.deleteIconInfo')" > delete_forever </span>
                                    <SelectButton v-model="element.isVisible" 
                                                  :options="isVisibleDetailStatus" 
                                                  optionLabel="label" 
                                                  optionValue="value" 
                                                  class="p-col-fixed" 
                                                  style="vertical-align: middle; width: 150px;" 
                                                  @change="onInputDetailStatusVislble($event, routeIndex, index)"
                                                  v-tooltip="$t('menuPanelConfigPanel.showIconInfo')"
                                                  />
                                </div>
                            </template>
                        </draggable>
                        <template v-if="EditableRoute.index == $constants.routeIndexStatus.ReceivedBox">
                            <div style="white-space: pre-wrap;"> {{$t('menuPanelConfigPanel.ReceivedBoxInfo')}} </div>
                        </template>
                        
                    </div>
                </template>
            </ScrollPanel>
        </div>
        <div class="p-pt-1 p-pb-2" style="margin: 0 auto; border-top:1px solid #e9ecef; width:100%;">
            <div class="text-right" style="margin-right: 15px;">
                <Button class="p-button-outlined p-button-info p-mr-3" style="" @click="onCancelDetailStatusList"> {{$t("utils.cancel")}} </Button>
                <Button class="p-button-outlined p-button-info" style="" @click="onSaveDetailStatusList"> {{$t("utils.save")}} </Button>
            </div>
        </div>
    </div>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';


import http from '../mixins/api'
import draggable from 'vuedraggable';
import Tooltip from 'primevue/tooltip'
import ScrollPanel from 'primevue/scrollpanel';
import SelectButton from 'primevue/selectbutton';



export default {
    name: 'MenuPanelConfigPanel',
    props: ["displayMenuPanelConfigPanel"],
    emits: ["emitEditableRouteList", "changeActiveMenuPanelTabIndex"],
    components: {
        Button,
        InputText,
        SelectButton,
        draggable,
        ScrollPanel,
    },
    data: function() {
        return {
            EditableRouteList: [],
            isVisibleDetailStatus: [
                {label: 'OFF', value: false},
                {label: 'ON', value: true},
            ],
            // deleteDetailStatus: [],  // _id,
            // addDetailStatus: [],     // _id, Text, ParentDetailFormStatusId,
            // mergeDetailStatus: [],   // _id, movedId, ParentDetailFormStatusId,
        }
    },
    created() {
    },
    computed: {
        isVisibleDetailStatusList: {
            get: function() {
            }
        },
    },
    watch: {
        displayMenuPanelConfigPanel: async function(value) { // 1 -> active, 2 -> update, 0 -> clear
            if(value == 1) {
                this.EditableRouteList = []
                const routeList = JSON.parse(JSON.stringify(this.$store.getters["routeStore/getRouteList"]))
                const editableRouteList = routeList.filter(x => x.editable)
                this.EditableRouteList = editableRouteList
            } else if(value == 2) {
                this.$emit("emitEditableRouteList", this.EditableRouteList)
            } else {
                this.EditableRouteList = []
            }
        }
    },
    methods: {
        onDeleteDetailStatusClick(e, item, routeIndex, index) {
            const self = this
            this.$confirm.require({
                defaultFocus: "reject",
                message: this.$i18n.tc('menuPanelConfigPanel.deleteDetailStatus', {STATUS: this.EditableRouteList[routeIndex].items[0].label}),
                rejectLabel: this.$i18n.tc("utils.cancel"),
                acceptLabel: "OK",
                accept: function() {
                    self.EditableRouteList[routeIndex].items.splice(index, 1)
                },
                reject: () => {
                }
            })
        },

        onPlusDetailStatusClick(e, item, routeIndex) {
            this.EditableRouteList[routeIndex].items.push({
                label: "",
                ParentDetailFormStatusId: this.EditableRouteList[routeIndex]._id,
                isDetailStatus: true,
                isVisible: true,
            })
            this.$toast.add({severity: 'success', summary: this.$i18n.tc("menuPanelConfigPanel.addedBelow"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
        },

        onInputDetailStatusLabel(e, routeIndex, index) {
            this.EditableRouteList[routeIndex].items[index].label = e.target.value
        },

        onInputDetailStatusVislble(e, routeIndex, index) {
            this.EditableRouteList[routeIndex].items[index].isVisible = e.value
        },

        onSaveDetailStatusList() {
            this.$emit('changeActiveMenuPanelTabIndex', 2)
        },

        onCancelDetailStatusList() {
            this.$emit('changeActiveMenuPanelTabIndex', 0)
        }
    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>

<style scoped>
.handle:hover {
    cursor: grab;
}
.handle:active {
    cursor:grabbing;
}
.defaultLabel {
    padding-left: 40px;
}
</style>
