<template>
    <ul v-if="items" class="layout-menu" style="user-select: text;">
        <template v-for="(item,i) of items">
            <li v-if="visible(item) && !item.separator && item.isVisible"
                :key="i"
                :class="[{'active-menuitem': activeIndex == item.index && !item.to && !item.disabled}]"
                style="user-select: text;"
                role="none">
                <router-link v-if="item.to && !item.disabled "
                    :to="item.to"
                    custom v-slot="{navigate, href}"
                >
                    <a
                        :href="href"
                        :target="item.target"
                        v-ripple
                        role="menuitem"
                        :style="item.style"
                        :class="[item.class, 'p-ripple', {'active-route': activeIndex === item.index, 'active-route-no-item': activeIndex === item.index, 'route-item-child': item.parentIndex, 'active-route-box':  activeIndex === item.parentIndex && $route.params.detailFormStatusId == item._id,'p-disabled': item.disabled, 'p-disabled': isMobile.isMobile() && !item.mobileAble, 'cursor_progress': waiting}]"
                        @click="onClick($event, navigate, item, item.index)"
                        draggable="false"
                    >
                        <i :class="['p-menuitem-icon', item.icon]">
                            <span class="material-symbols-outlined" v-if="item.frontFontIcon == 'inbox'"> inbox </span>
                            <span class="material-symbols-outlined" v-if="item.frontFontIcon == 'done_all'"> done_all </span>
                            <span class="material-symbols-outlined" v-if="item.frontFontIcon == 'remove_done'"> remove_done </span>
                            <span class="material-symbols-outlined" v-if="item.frontFontIcon == 'home_storage'"> home_storage </span>
                            <span class="material-symbols-outlined" v-if="item.frontFontIcon == 'delete'"> delete </span>
                            <span class="material-symbols-outlined" v-if="item.frontFontIcon == 'undo'"> undo </span>
                        </i>
                        <span class="p-menuitem-text" :class="[{'active-route-text': activeIndex === item.parentIndex && $route.params.detailFormStatusId == item._id}]" style="user-select: text;" :style="item.fontStyle"> {{ item.label }} </span>
                        <template v-if="item.info">
                            &nbsp;&nbsp;
                            <i v-tooltip="item.info" class="pi pi-info-circle" style=""></i>
                        </template>
                        <template v-if="item.items && item.items.length">
                            <Button 
                                v-if="expandedIndexList.includes(item.index)" 
                                @click="onExpandMenuItemClick($event, item, item.index)" 
                                class="p-button-rounded p-button-text p-button-plain" 
                                icon="pi pi-fw pi-angle-up" 
                                :style="item.style"
                                role="separator"
                                />
                            <Button
                                v-else
                                @click="onExpandMenuItemClick($event, item, item.index)" 
                                class="p-button-rounded p-button-text p-button-plain" 
                                icon="pi pi-fw pi-angle-down" 
                                :style="item.style"
                                role="separator" 
                                />
                        </template>
                    </a>

                </router-link>
                <a
                    :target="item.target"
                    v-ripple
                    v-else
                    role="menuitem"
                    :style="item.style"
                    :class="[item.class, 'p-ripple', {'active-in-route': activeIndex === item.index, 'p-disabled': item.disabled, 'cursor_progress': waiting, 'p-disabled': isMobile.isMobile() && !item.mobileAble}]"
                    @click="onClick($event, navigate, item, item.index)"
                    draggable="false"
                >
                    <i :class="['p-menuitem-icon', item.icon]">
                        <span class="material-symbols-outlined" v-if="item.frontFontIcon == 'inbox'"> inbox </span>
                        <span class="material-symbols-outlined" v-if="item.frontFontIcon == 'done_all'"> done_all </span>
                        <span class="material-symbols-outlined" v-if="item.frontFontIcon == 'remove_done'"> remove_done </span>
                        <span class="material-symbols-outlined" v-if="item.frontFontIcon == 'home_storage'"> home_storage </span>
                        <span class="material-symbols-outlined" v-if="item.frontFontIcon == 'delete'"> delete </span>
                        <span class="material-symbols-outlined" v-if="item.frontFontIcon == 'undo'"> undo </span>
                    </i>
                    <span class="p-menuitem-text" 
                          :class="[{'active-route-text': activeIndex === item.parentIndex && $route.params.detailFormStatusId == item._id}]" 
                          style="user-select: text;" 
                          :style="item.fontStyle"
                          >
                          {{ item.label }} 
                    </span>
                    <template v-if="item.info">
                        &nbsp;&nbsp;
                        <i v-tooltip="item.info" class="pi pi-info-circle" style=""></i>
                    </template>
                    <template v-if="item.items && item.items.length">
                        <Button 
                            v-if="expandedIndexList.includes(item.index)" 
                            @click="onExpandMenuItemClick($event, item, item.index)" 
                            class="p-button-rounded p-button-text p-button-plain" 
                            icon="pi pi-fw pi-angle-up" 
                            :style="item.style"
                            role="separator"
                            />
                        <Button
                            v-else
                            @click="onExpandMenuItemClick($event, item, item.index)" 
                            class="p-button-rounded p-button-text p-button-plain" 
                            icon="pi pi-fw pi-angle-down" 
                            :style="item.style"
                            role="separator" 
                            />
                    </template>
                </a>
                <transition name="layout-submenu-wrapper">
                <!-- :class="[{'active-menuitem': expandedIndexList.includes(item.index) &#38;&#38; !item.to &#38;&#38; !item.disabled}]" -->
                    <!-- <MenuPanel v-show="item.items &#38;&#38; activeIndex == item.index" :items="visible(item) &#38;&#38; item.items" @menuitem-click="$emit('menuitem-click', $event)"></MenuPanel> -->
                    <MenuPanel v-show="item.items && expandedIndexList.includes(item.index)" :items="visible(item) && item.items" @menuitem-click="$emit('menuitem-click', $event)"></MenuPanel>
                </transition>
            </li>
            <li class="p-menu-separator" :style="item.style" v-if="visible(item) && item.separator" :key="'separator' + i" role="separator"></li>
        </template>
    </ul>
</template>

<script>
import PanelMenu from 'primevue/panelmenu';
import Button from 'primevue/button';
import TabMenu from 'primevue/tabmenu';
import Ripple from 'primevue/ripple';
import Tooltip from 'primevue/tooltip';
import isMobile from '@/mixins/device'

export default {
    name: 'MenuPanel',
    props: ['items'],
    components: {
        PanelMenu,
        Button,
        TabMenu,
    },
    // props: {
    //   msg: String
    // }
    data: function() {
        return {
            expandedKeys: {'0': true},
            isMobile,
        }
    },
    computed: {
        expandedIndexList: {
            get: function() {
                const expandedIndexList = this.$store.getters["routeStore/getExpandedIndexList"]
                expandedIndexList.push(this.activeIndex)
                return expandedIndexList
            }
        },
        activeIndex: {
            get: function() {
                return this.$store.getters["routeStore/getActiveRouteCode"]
            }
        },
        waiting: {
            get: function() {
                return this.$store.getters["routeStore/getWaiting"]
            }
        }
    },
    methods: {
        onClick(event, navigate, item, index) {
            // this.$store.commit("routeStore/setActiveRouteCode", index)

            this.itemClick({
                originalEvent: event,
                item: item,
                navigate: navigate
            })
            this.$store.commit("routeStore/changeExpandedIndexList", index)
            // this.$emit('click', );
        },

        onExpandMenuItemClick(event, item, index) {
            // if (item.disabled) {
            //     event.preventDefault();
            //     return;
            // }
            // if (!item.to && !item.url) {
            //     event.preventDefault();
            // }
            // if (item.command) {
            //     item.command({originalEvent: event, item: item});
            // }
            // if (item.to && event.navigate) {
            //     event.navigate(event.originalEvent);
            // }
            // console.log(index)
            // this.hide()

            // this.$store.commit("routeStore/changeExpandedIndexList", index)
        },

        onMenuItemClick(event, item, index) {
            if (item.disabled) {
                event.preventDefault();
                return;
            }
            if (!item.to && !item.url) {
                event.preventDefault();
            }
            //execute command
            if (item.command) {
                item.command({originalEvent: event, item: item});
            }
            this.$store.commit("routeStore/setActiveRouteCode", index)
            if (item.to && event.navigate) {
                event.navigate(event.originalEvent);
            }
            this.hide()
        },
        hide() {
            this.overlayVisible = false;
            this.target = null;
            this.relativeAlign = false;
        },
        visible(item) {
            return (typeof item.visible === 'function' ? item.visible() : item.visible !== false);
        },
        itemClick(event) {
            const item = event.item;
            if (item.disabled) {
                return;
            }

            if (item.command) {
                item.command(event);
            }

            if (item.to && event.navigate) {
                event.navigate(event.originalEvent);
            }

            this.hide();
        },
    },
    directives: {
        'ripple': Ripple,
        "tooltip": Tooltip
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

