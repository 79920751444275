<template>
    <div class="container">

        <div class="plan">
            <router-view/>
        </div>
    </div>
</template>


<script>

export default {
    name: 'Plans',
    components: {
    },
    data: function() {
        return {
        }
    },


    methods: {
    }
}
</script>

<style scoped>
.container {
    width: 100vw;
    height: 100%;
    /* background-color: #f8f8f8; */
}
.plan {
    max-width: 1200px;
    padding: 0 16px 16px 16px;
    margin: 0 auto;
}
</style>
