<template>
    <div class="p-d-flex" style="width:100%; height: 100%; position: absolute;">
        <div class="p-d-flex p-flex-column p-ai-end" style="width:100%;height: 100%;">
            <div 
                style="width: 100%; height: 30px; position: absolute; top: 0px; left: 0px; background-color: rgba(245, 158, 8, 0.16); color: #FBC02D; text-align: center; line-height: 30px; font-size: 14px;" 
                v-if="willEndFormTemplate"
                >
                {{$t("admin.willEndFormTemplate")}}
                <span class="material-symbols-outlined cancelWillEndFormTemplateIcon cursor_pointer" @click="willEndFormTemplate=false"> cancel </span>
            </div>
            <div class="" v-if="isMobile.isMobile()">
                <div class="p-col-12 no_padding" style="">
                    <div @click="onListOverlayPanelClick" class="p-d-flex p-d-column align-item-center settingContainer" style="padding: 2px;" v-tooltip.bottom="{value: $t('menuPanelConfigPanel.userInfoTooltip'), fitContent: false}" >
                        <Button icon="pi pi-user "  class="p-button-rounded p-button-secondary p-button-outlined" style="margin-right: 10px;"  />
                        <span style="font-size: 14px;white-space: break-spaces;place-self: center;">
                            {{loginUserInfo}}
                            <OverlayPanel ref="op"  :dismissable="true" class="settingOverlayPanel">
                                <Menu :model="accountMenuItems"> </Menu>
                            </OverlayPanel>
                        </span>
                    </div>
                </div>
                <div class="p-col-12 no_padding" style="">
                    <div class="p-col p-d-flex align-item-end p-pl-4" style="min-width: 0px; ">
                        <Dropdown 
                            style="min-width: 170px; max-width: 70%;" 
                            class="text-left text-ellipsis-dropbox smart-dropbox selectDepartmentDropbox" 
                            panelClass=" max-800-dropbox fixed-dropbox-panel"
                            v-model="selectedDepartment" 
                            :options="Workspaces" 
                            optionLabel="DisplayName" 
                            optionValue="_id"  
                            :beforeChangeEvent="beforeChange"
                            @change="onWorkspacesChange" 
                            :disabled="activeMenuPanelTabIndex == 1" 
                            v-tooltip.bottom="{value: $t('menuPanelConfigPanel.whatActiveDepartment'), fitContent: false}" />
                    </div>
                </div>
            </div>
            <div class="p-d-flex p-flex-row" style="height: 44px;" v-else >
                <div class="p-grid p-field no_margin " style=" max-width: 70%; ">
                    <div class="p-col p-d-flex align-item-center p-pl-4" style="min-width: 0px; ">
                        <Dropdown 
                            style="position: absolute; left: 35px; min-width: 170px; max-width: 70%;" 
                            class="text-left text-ellipsis-dropbox smart-dropbox selectDepartmentDropbox" 
                            panelClass=" max-800-dropbox fixed-dropbox-panel"
                            v-model="selectedDepartment" 
                            :options="Workspaces" 
                            optionLabel="DisplayName" 
                            optionValue="_id"  
                            :beforeChangeEvent="beforeChange"
                            @change="onWorkspacesChange" 
                            :disabled="activeMenuPanelTabIndex == 1" 
                            v-tooltip.bottom="{value: $t('menuPanelConfigPanel.whatActiveDepartment'), fitContent: false}" />
                    </div>
                </div>
                <div style="flex: 1;"> </div>
                <div>
                    <div @click="onListOverlayPanelClick" class="p-d-flex p-d-column align-item-center settingContainer" style="padding: 2px;" v-tooltip.bottom="{value: $t('menuPanelConfigPanel.userInfoTooltip'), fitContent: false}" >
                        <Button icon="pi pi-user "  class="p-button-rounded p-button-secondary p-button-outlined" style="margin-right: 10px;"  />
                        <span style="font-size: 14px;white-space: break-spaces;place-self: center;">
                            {{loginUserInfo}}
                            <OverlayPanel ref="op"  :dismissable="true" class="settingOverlayPanel">
                                <Menu :model="accountMenuItems"> </Menu>
                            </OverlayPanel>
                        </span>
                    </div>
                </div>
            </div>
            <div style="width: 100%;">
                <Button icon="pi pi-bars" class="p-button-rounded p-button-secondary p-button-text"  v-if="isMobile.isMobile()" @click="onMenuToggle" />
            </div>
            <template v-if="!isMobile.isMobile()">
                <Splitter :gutterSize=6 class="p-d-flex p-flex-row p-as-stretch" style="height: calc(100% - 44px); border-top: 0px;">
                    <SplitterPanel size="menuPanelSize" :minSize=10 style="overflow-y: auto; position: relative;" >
                        <transition name="layout-sidebar">
                            <div :class="sidebarClass" @click="onSidebarClick">
                                <div class="p-pt-2 p-pb-2" :class="{adminSidebarTopFront: activeMenuPanelTabIndex == 0}" style="width:100%; position: relative;text-align: center;" >
                                    <Button class="p-mt-2 p-mb-2 p-button-outlined p-button-info no_margin" style="z-index: 2;" @click="createNewTemplate"  :disabled="activeMenuPanelTabIndex == 1"> {{$t("utils.createNewTemplate")}} </Button>
                                    <div class="" style="width:100%; position: absolute; text-align: right; height: 100%; top: 0px; z-index: 1" >
                                        <i class="pi pi-cog cursor_pointer p-mr-2" @click="onMenuPanelCogIconClick" style="position: absolute; bottom: 0px; right: 0px; font-size: 1.3rem; pointer-events: auto;" v-if="activeMenuPanelTabIndex == 0" :class="{'p-disabled': disabledActiveMenuPanelTabIndex, 'p-disabled-hard': disabledActiveMenuPanelTabIndex}" v-tooltip="$t('menuPanelConfigPanel.whatDiv')"></i>
                                    </div>
                                </div>
                                <TabView class="adminMenuPanelTab" :activeIndex="activeMenuPanelTabIndex" >
                                    <TabPanel>
                                        <MenuPanel :items="menuPanelItems" @menuitem-click="onMenuItemClick"/>
                                    </TabPanel>
                                    <TabPanel>
                                        <MenuPanelConfigPanel :displayMenuPanelConfigPanel="displayMenuPanelConfigPanel" @changeActiveMenuPanelTabIndex="changeActiveMenuPanelTabIndex($event)" @emitEditableRouteList="emitEditableRouteList($event)"/>
                                    </TabPanel>
                                </TabView>

                            </div>
                        </transition>
                        <div style="width: 100%; text-align: right;">
                            <span class="gray-footer2 paperFooter" style="font-size: 16px;  padding-right: 1em;"  @click="jump2Homepage" >BankInvoice Paper</span>
                        </div>
                    </SplitterPanel>
                    <SplitterPanel :size=80 style="max-width: 80%" >
                        <router-view/>
                    </SplitterPanel>
                </Splitter>
                <div class="progressSpinnerContainer" style="z-index:2;" v-if="showGlobalAdminSpinner">
                    <ProgressSpinner />
                </div>
            </template>
            <template v-else>
                <div v-bind:class="containerClass" class="" style="" @click="onWrapperClick" >
                    <div :class="sidebarClass" @click="onSidebarClick" style="">
                        <div class="" style="width:100%; position: relative;text-align: center;" >
                            <Button class="p-mt-2 p-mb-2 p-button-outlined p-button-info" @click="createNewTemplate" :disabled="isMobile.isMobile()"> {{$t("utils.createNewTemplate")}} </Button>
                        </div>
                        <MenuPanel :items="menuPanelItems" @menuitem-click="onMenuItemClick"/>
                    </div>
                    <div class="layout-main-container">
                        <div class="layout-main ">
                            <router-view/>
                        </div>
                    </div>
                    <transition name="layout-mask">
                        <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
                    </transition>
                </div>
            </template>
        </div>
    </div>
    <upgradePopup />
</template>

<script>
// @ is an alias to /src
import MenuPanel from '@/components/MenuPanel.vue'

import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Button from 'primevue/button';
import OverlayPanel from 'primevue/overlaypanel';
import Menu from 'primevue/menu';
import Dropdown from 'primevue/dropdown';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import MenuPanelConfigPanel from '@/components/MenuPanelConfigPanel.vue'
import ProgressSpinner from 'primevue/progressspinner';



import Dialog from 'primevue/dialog';
import upgradePopup from '@/components/upgradePopup';

import isMobile from '@/mixins/device'
import http from '@/mixins/api'
import socketHelper from "@/mixins/socketHelper"
import Tooltip from 'primevue/tooltip'

export default {
    name: 'Admin',
    components: {
        MenuPanel,
        Menu,
        Splitter,
        SplitterPanel,
        Button,
        OverlayPanel,
        Dropdown,
        TabView,
        TabPanel,
        MenuPanelConfigPanel,
        Dialog,
        upgradePopup,
        ProgressSpinner,
    },
    data: function() {
        return {
            isMobile,
            layoutColorMode: "light",
            // layoutColorMode: "dark",
            showSpinSettingButton: false,
            accountMenuItems: [],
            selectedDepartment: {},
            activeMenuPanelTabIndex: 0,
            displayMenuPanelConfigPanel: 0,
            mobileMenuActive: false,
            layoutMode: 'static',
            staticMenuInactive: false,
            overlayMenuActive: false,
            menuPanelSize: 20,
            willEndFormTemplate: false,

        }
    },
    created() {
        this.mobileMenuActive =  isMobile.isMobile()
        socketHelper.connect()
        socketHelper.join()
        socketHelper.setOn()
        this.init()
        this.FindPublicPeriodWillFinishedForm()
    },
    computed: {
        menuPanelItems: {
            get: function() {
                // const routeList = this.$store.getters["routeStore/getRouteList"]
                const routeList = this.$store.state.routeStore.routeList
                return routeList
            }
        },
        sidebarClass() {
            return ['layout-sidebar', {
                'layout-sidebar-dark': this.layoutColorMode === 'dark',
                'layout-sidebar-light': this.layoutColorMode === 'light'
            }];
        },
        loginUserInfo: {
            get: function() {
                // const DepartmentList = this.$store.getters["userBranchInfo/getMemberList"]
                const userInfo = this.$store.getters["auth/getUserInfoInState"]
                if(userInfo) {
                    // const count = DepartmentList.filter(x => x.WorkUserId == userInfo._id).length
                    const Email = userInfo.Email
                    if(isMobile.isMobile()) {
                        return Email + "  ";
                    }
                    // const VisibleIdList = this.$store.getters["userBranchInfo/getVisibleIdList"].filter(x => x.Type == "User")

                    return Email + "  "
                    // return Email + " " + count + " " + this.$i18n.tc("utils.department") + " " + VisibleIdList.length + " " + this.$i18n.tc("utils.document") + "  "
                }

            },
        },
        disabledActiveMenuPanelTabIndex: {
            get: function() {
                return this.$route.params.departmentId == this.$constants.AllDepartmentIdConstants
            }
        },
        Workspaces: {
            get: function() {
                const workspace = this.$store.getters["userBranchInfo/getWorkspace"]
                workspace.push({
                    _id: this.$constants.AllDepartmentIdConstants,
                    DisplayName: this.$i18n.t("accountSetting.allDepartment"),
                })

                return workspace
            }
        },

        selectedDepartment: {
            get: function() {
                return this.$store.getters["userBranchInfo/getActiveDepartmentId"]
            },
            set: function(value) {
                this.$store.dispatch("userBranchInfo/setActiveDepartmentId", value)
            }
        },

        showGlobalAdminSpinner: {
            get: function() {
                return this.$store.getters["isShowGlobalAdminSpinner"]
            },
        },

        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
                // 'p-input-filled': this.$primevue.config.inputStyle === 'filled',
                // 'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },

    },

    watch: {
        activeMenuPanelTabIndex: function(value)  {
            if(value == 0) {
                this.menuPanelSize = 20
            } else {
                this.menuPanelSize = 40
            }
        }
    },


    methods: {
        async init() {
            await this.$store.dispatch("userBranchInfo/setMemberList")
            this.selectedDepartment = this.$route.params.departmentId ? this.$route.params.departmentId : this.$constants.AllDepartmentIdConstants
            
        },

        async beforeChange(e) {
            const self = this;
            const editted = this.$store.getters["editTemplate/getEdited"]
            if(editted) {
                const callback = new Promise(res => {
                    this.$confirm.require({
                        message: this.$i18n.tc("formDataTable.isNotSave"),
                        acceptLabel: this.$i18n.tc("formDataTable.isNotSaveAccept"),
                        rejectLabel: this.$i18n.tc("utils.back"),
                        isFlexFooter: true,
                        accept: function() {
                            self.$store.commit("editTemplate/setEdited", false)
                            self.$store.commit("gridDataStore/setEditableForm", false)
                            res(true)
                        },
                        reject: () => {
                            res(false)
                        }
                    })
                })

                return await callback

            } else {
                self.$store.commit("gridDataStore/setEditableForm", false)
                return true
            }
        },

        onWorkspacesChange(e) {
            this.workspacesChange(e)
        },

        async workspacesChange(e) {
            this.selectedDepartment = e.value
            this.$store.commit('routeStore/setLoaded', false)
            await this.$store.dispatch('routeStore/getRouteList', {DepartmentId: e.value})
            // this.$router.push({name: this.$route.name, params: {departmentId: e.value, detailFormStatusId: this.$route.params.detailFormStatusId}})
            this.$router.push({name: this.$route.meta ? this.$route.meta.name : this.$route.name, params: {departmentId: e.value, detailFormStatusId: this.$route.params.detailFormStatusId }})
            this.$store.commit('routeStore/closeAllRoute')
            this.$store.commit('routeStore/expandAllRoute')
        },

        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
            this.menuClick = false;
        },

        onMenuToggle(e) {
            this.menuClick = true;
            if (!isMobile.isMobile()) {
                if (this.layoutMode === 'overlay') {
                    if(this.mobileMenuActive === true) {
                        this.overlayMenuActive = true;
                    }
                    this.overlayMenuActive = !this.overlayMenuActive;
                    this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            } else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }
            e.preventDefault();
        },


        async onClickUpgradeButton() {
            const DepartmentList = this.$store.getters["userBranchInfo/getBelongDepartmentList"]
            if(DepartmentList.length) {
                const departmentId = DepartmentList[0]._id
                this.$router.push({ path: `/plans/info/${departmentId}`});
            }
        },
            
        async FindPublicPeriodWillFinishedForm() {
            const res = await http.post("/form/FindPublicPeriodWillFinishedForm")
            if(res.Success) {
                this.willEndFormTemplate = res.IsFinish
            }
        },

        async onClickSettingButton() {
            this.showSpinSettingButton = true
            const res = await http.post("/user/GetUpdateSettingLink")
            if(res.Success) {
                const url = res.redirect_url
                window.open(url, '_blank')
            }
            this.showSpinSettingButton = false
        },

        async createNewTemplate() {
            const res = await http.post("/form/CreateNewTemplateForm", {DepartmentId: this.$route.params.departmentId})
            if(res.Success) {
                if(res.Message) {
                    this.$toast.add({severity:'success', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                }
                console.log(this.$router.currentRoute.value.name == "draft")
                if(this.$router.currentRoute.value.name == "draft") {
                    this.$store.commit('gridDataStore/setActiveStatus', {"Type": "Template", "Status": 0})
                    this.$store.commit('gridDataStore/setLoading', true)
                    await this.$store.dispatch('gridDataStore/reloadGridDataList')
                    this.$store.commit('gridDataStore/setLoading', false)
                } else {
                    this.$store.commit('gridDataStore/setSelectedGridDataList', [])
                    this.$router.push({name: "draft", params: {departmentId: this.$route.params.departmentId}})
                }
            } else {
                if(res.Message) {
                    this.$toast.add({severity:'error', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                }
            }
        },

        onMenuItemClick() {

        },

        onListOverlayPanelClick(e) {
            const edited = this.$store.getters["editTemplate/getEdited"]
            if(edited) {
                const ok = confirm(this.$i18n.tc("formDataTable.isNotSave"))
                if(ok) {
                    this.$refs.op.toggle(e);
                }
            } else {
                this.$refs.op.toggle(e);
            }
            this.accountMenuItems = [
                // {
                //     label: this.$i18n.tc("accountSetting.workspace"),
                //     icon: 'pi pi-at',
                //     disabled: isMobile.isMobile(),
                //     command: (e) => {
                //         this.$router.push("/admin/workspace")
                //     }
                // },
                {
                    label: this.$i18n.t("accountSetting.setting"),
                    icon: 'pi pi-cog',
                    command: (e) => {
                        this.onClickSettingButton()
                    }
                },
                {
                    label: this.$i18n.t("accountSetting.upgrade"),
                    icon: 'pi pi-cog',
                    command: (e) => {
                        this.onClickUpgradeButton()
                    }
                },
                {
                    label: this.$i18n.t("accountSetting.signout"),
                    icon: 'pi pi-sign-out',
                    command: (e) => {
                        // this.$router.push("/admin/logout")
                        if (this.$route.name != "logout") {
                            localStorage.redirect_url = location.href;
                        }
                        this.$router.push({name: "logout"})
                    }
                },
            ]

        },

        onClosePlanDialog(e, viewPlan) {
            this.$store.commit("planedStore/setActivePlanAlert", false)
            if(viewPlan) {
                this.onClickUpgradeButton()
            } else {
                this.$store.dispatch("planedStore/fireCallbackFunction")
            }

        },

        jump2Homepage() {
            const link = process.env.VUE_APP_HOMEPAGE
            window.open(link, '_blank')

        },

        async onMenuPanelCogIconClick() {
            if(!this.disabledActiveMenuPanelTabIndex) {
                this.activeMenuPanelTabIndex = 1
                this.displayMenuPanelConfigPanel = 1
            }
        },

        async onMenuPanelBackIconClick() {
            this.displayMenuPanelConfigPanel = 0
            this.activeMenuPanelTabIndex = 0
        },

        async onMenuPanelCheckIconClick() {
            this.displayMenuPanelConfigPanel = 2
            this.activeMenuPanelTabIndex = 0
        },

        async emitEditableRouteList(EditableRouteList) {
            const res = await http.post("/user/UpdateDetailStatusFormInfo", {editableRouteList: EditableRouteList, DepartmentId: this.$route.params.departmentId})
            this.$store.commit("routeStore/setLoaded", false)
            await this.$store.dispatch('routeStore/getRouteList', {DepartmentId: this.$route.params.departmentId})
            if(res.Success) {
                this.$store.commit("editTemplate/setDetailFormStatusList", res.ReceivedDetailFormStatusList)
                this.$toast.add({severity: 'success', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            } else {
                this.$toast.add({severity: 'error', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }

        },

        async changeActiveMenuPanelTabIndex(tabIndex) {
            if(tabIndex == 2) {
                this.displayMenuPanelConfigPanel = 2
            } else {
                this.displayMenuPanelConfigPanel = 0
            }
            this.activeMenuPanelTabIndex = 0
            
        }
    },
    directives: {
        'tooltip': Tooltip
    },
}
</script>
<style scoped>
.settingContainer {

}
.settingContainer:hover {
    cursor: pointer;
    background-color: #f2f5f7;
}
.adminSidebarTopFront {
    background-color: #f8f9fa;
}
.cancelWillEndFormTemplateIcon {
    height: 16px;
    vertical-align: middle;
    font-size: 16px;
}
.selectDepartmentDropbox {
    box-shadow: none;
    outline: none;
    display: flex;
    border: none;
    background: transparent;
}
.selectDepartmentDropbox:hover {
    background: #f2f5f7;
}
</style>
