<template>
    <div class="p-p-4">
        <div class="p-grid p-jc-center " >
            <div class="p-col-9 p-md-9 p-lg-9 p-grid p-nogutter">
                <div class="p-col-12 p-grid p-jc-center">
                    <div class="p-col-9 p-grid p-field">
                        <h4 class="" style="color: black" >{{$t("changePassword.title")}}</h4>
                    </div>
                </div>
                <div class="p-col-12 p-grid p-jc-center p-as-center">
                    <div class="p-col-8 p-field p-grid" style="margin-bottom: 0.1rem;">
                        <span class="p-col-fixed input_label" style="justify-content: right;" >{{$t("changePassword.Email")}}</span>
                        <!-- <div  class="p&#45;col"> -->
                            <InputText class="p-col" v-model.trim="Email" />
                        <!-- </div> -->
                    </div>
                </div>
                <div class="p-col-12 p-grid p-jc-center">
                    <div class="p-col-8 p-grid p-field  p-nogutter" style="margin-bottom: 0.1rem;">
                        <span class="p-col-fixed input_label" style=" justify-content: right;padding: 0rem 0.5rem 0rem 0.5rem;">{{$t("changePassword.Password")}}</span>
                        <Password class="p-col" v-model.trim="Password" :feedback="false"  inputStyle="width:100%" />
                    </div>
                </div>
                <div class="p-col-12 p-grid p-jc-center">
                    <div class="p-col-8 p-grid p-field p-nogutter" style="margin-bottom: 0px;">
                        <span class="p-col-fixed input_label" style="justify-content: right;padding: 0rem 0.5rem 0rem 0.5rem;">{{$t("changePassword.nextPassword")}}</span>
                        <Password class="p-col" v-model.trim="nextPassword" :feedback="false"   inputStyle="width:100%" />
                    </div>
                </div>
                <div class="p-col-12 p-grid p-jc-center">
                    <div class="p-col-8 p-grid p-field" style="margin-bottom: 0px;">
                        <label class="p-col-fixed" style="margin-left: 172px;font-size: 7pt;font-weight: 100;">{{$t("changePassword.dontUse")}}</label>
                    </div>
                </div>
                <div class="p-col-12 p-grid  p-jc-center" style="">
                    <div class="p-col-8 p-grid p-field" style="justify-content: flex-end; margin-bottom: 0px;">
                        <Button class="p-button-outlined p-button-info" @click="onChangePasswordClick">{{$t("changePassword.update")}}</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import http from '../mixins/api'
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Button from 'primevue/button';

export default {
    name: 'ChangeWorkerPassword',
    components: {
        Button,
        InputText,
        Password,
    },
    data() {
        return {
            UUID: "",
            UserId: "",
            Email: "",
            Password: "",
            nextPassword: "",
        }
    },
    async beforeRouteEnter(route, redirect, next) {
        const query = route.query
        const UUID = query.UUID
        const UserId = query.USERID
        next(vm => vm.setData({
            UUID : UUID,
            UserId : UserId
        }))
    },
    created() {
    },
    mounted() {
    },
    computed: {
    },
    methods: {
        setData: function(data) {
            this.UUID = data.UUID
            this.UserId = data.UserId
        },
        onChangePasswordClick: async function() {
            const res = await http.post("/user/ChangePassword", {UUID: this.UUID, UserId: this.UserId, Email: this.Email, Password: this.Password, NextPassword: this.nextPassword})
            if(res.Success) {
                this.$toast.add({severity:'success', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                this.$store.commit("auth/logout")
                this.$store.commit("auth/login", {userInfo: res.userInfo, token: res.token})
                this.$router.push('/admin_login')

            } else {
                this.$toast.add({severity:'error', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
        },
    }
}
</script>
<style scoped>
.input_label {
    width:180px;
    color: #666;
    font: 400 13px/17px helvetica, arial, verdana, sans-serif;
    height: 34px;
    line-height: 34px;
    text-align: right;
}
</style>

