<template>
    <div class="p-d-flex  p-flex-column" style="width:100%; height: 100%; position: absolute;" v-if="Finished" >
        <div class=" p-as-center" style="display: table;height:100%;">
            <div style="margin: 0 auto; display: table-cell;   vertical-align: middle;width: 100%; ">
                <div class="">
                    <div>
                        <div class="p-text-center loginDocumentText " style="font-size: 20px">
                            {{DocumentName}}
                        </div>
                        <div class="p-text-center loginLogoMini">{{$t('utils.finishedFormText')}}</div>
                    </div>
                </div>
            </div>
        </div>
        <BankInvoicePaperFooter />
    </div>
    <div class="p-d-flex  p-flex-column" style="width:100%; height: 100%; position: absolute;" v-else >
        <div class="container width-100-phone" style="padding-top: 5px; text-align: right;" >
            <Dropdown v-model="selectedLanguage" :options="languages" optionLabel="name" optionValue="value" class="selectLanguageInputFormDropdown" />
        </div>
        <div class=" p-as-center  width-100-phone" style="display: table;height:100%;">
            <div style="margin: 0 auto; display: table-cell;   vertical-align: middle;" class="container doorContainer">
                <div class="">
                    <div class="p-text-center loginDocumentText">
                        <h1 class="" style="text-align: left; font-size: 20px; font-weight: normal; padding-left: 8px; border-left: 4px solid #4a525a;word-break: break-word;"> {{DocumentName}} </h1>
                    </div>
                </div>
                <div class="p-field">
                    <div style="" class=" p-grid p-nogutter">
                        <label style="font-size: 12px;" >{{$t('formValidationDoors.inputInfo')}}</label>
                    </div>
                    <div style="" class=" p-grid p-nogutter">
                        <Password class="p-col FormViewPasswordInput" v-model="password" :feedback="false" ref="passwordInputForm" :placeholder="$t('utils.password')" toggleMask />
                        <Button style="font-size: 14px;" type="button" class="p-button-outlined p-button-secondary" :label="$t('utils.ok')" @click="onPasswordButtonClick" />
                    </div>
                </div>
            </div>
        </div>
        <template v-if="displayProgressSpinner">
            <div class="miniProgressSpinnerContainer">
                <ProgressSpinner strokeWidth="4" />
            </div>
        </template>
        <div class="p-text-center loginLogoMiniHello">Hello Paperless World</div>
        <BankInvoicePaperFooter />
    </div>
</template>
<script>
import http from '../mixins/api'
import Password from 'primevue/password';
import ProgressSpinner from 'primevue/progressspinner';

import Button from 'primevue/button';
import BankInvoicePaperFooter from '@/components/BankInvoicePaperFooter';
import Dropdown from 'primevue/dropdown';

export default {
    name: 'FormValidationDoors',
    components: {
        Button,
        BankInvoicePaperFooter,
        ProgressSpinner,
        Password,
        Dropdown,
    },
    data: function(){
        return {
            DocumentName: "",
            TemplateId: null,
            password: "",
            Finished: false,
        }
    },
    computed: {
        selectedLanguage: {
            get: function() {
                return this.$store.getters["applicantStore/getUserLanguage"]
            },
            set: function(value) {
                this.$store.commit("applicantStore/setUserLanguage", value)
            },
        },

        languages: {
            get: function() {
                return this.$store.getters["applicantStore/getUserLanguageList"]
            },
        }
    },
    async beforeRouteEnter(route, redirect, next) {
        const DepartmentUUID = route.params.departmentUUID
        const LinkUUID = route.params.linkUUID
        const res = await http.post('/form/IsPublicedFormTemplate', {DepartmentUUID: DepartmentUUID, LinkUUID: LinkUUID}, {}, false, false);
        if(res.Success) {
            const res_passed = await http.post("/form/checkFormTemplatePassword", {DepartmentUUID: DepartmentUUID, LinkUUID: LinkUUID}, {}, false, false)
            if (res_passed.Success) {
                if(res_passed.NonePassword) {
                    next({name: "Doors", params: {departmentUUID: DepartmentUUID, linkUUID: LinkUUID}})
                    return
                }
            }
            next(vm => vm.setData({id: res.TemplateId, DocumentName: res.DocumentName}))
        } else {
            next(vm => vm.wrongPage({DocumentName: res.DocumentName}))
        }
    },
    methods: {
        wrongPage: function(data) {
            this.DocumentName = data.DocumentName
            this.Finished = true
        },
        setData: function(data) {
            this.TemplateId = data.id
            this.DocumentName = data.DocumentName
            this.$store.commit("applicantStore/setInitUserLanguage")
            if (this.$refs.passwordInputForm.$el)
                this.$refs.passwordInputForm.$el.firstChild.focus()
        },
        onPasswordButtonClick: async function() {
            const password = this.password
            console.log(this.TemplateId, password)
            const res = await http.post("/form/VerificationFormTemplatePassword", {templateId: this.TemplateId, password: password})
            console.log(res)
            if(res.Success) {
                this.$router.push({ path: res.path });
                this.$store.commit('applicantStore/setIsPassedBeforeForm', true)
            } else {
                this.$toast.add({severity:'error', summary: "error", life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
        },
    }
}
</script>

<style scoped>
.loginDocumentText {
    text-align: center;
    font-size: 46pt;
    font-style: normal;
    font-weight: 300;
    color: #666666;
    /* font-family: 'MS P明朝',courier,'SF Pro Icons','Helvetica Neue',Helvetica,Arial,sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    max-width: 100%;
}
.disabledColor {
    color: #495057;
}
</style>
