<template>
    <div> redirecting... </div>
    <Dialog v-model:visible="redirectConfirmDialog" :closable="false">
        <div style="white-space: pre-line;">
            {{$t('inputFormsRedirect.confirmText', {TEXTURL: TEXTURL, URL: URL})}}
        </div>
        <div class="p-d-flex p-jc-between p-mt-4" :class="{'mobileButtonFields': isMobile.isMobile()}" >
            <Button
                :label="$t('utils.cancel')" 
                class="p-button-secondary p-button-outlined" 
                @click="closeDialog"
            />
            <Button 
                :label="$t('inputFormsRedirect.openRedirectUrl', {URL: URL})" 
                :class="{'p-mb-1': isMobile}"
                class="p-button-secondary" 
                @click="openRedirectUrl"
            />
            <Button 
                :label="$t('inputFormsRedirect.openRedirectUrlText', {URL: TEXTURL})" 
                :class="{'p-mb-1': isMobile}"
                class="" 
                @click="openRedirectUrlText" />
        </div>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import isMobile from '@/mixins/device'


export default {
    name: 'InputformsRedirect',
    components: {
        Dialog,
        Button,
    },
    data: function() {
        return {
            TEXTURL: "",
            URL: "",
            redirectConfirmDialog: false,
            isMobile,
        }
    },
    async beforeRouteEnter(route, redirect, next) {
        next(vm => vm.initRoute())
    },
    created() {
    },
    computed: {
    },
    methods: {
        initRoute() {
            const self = this
            let query = this.$route.query
            const redirectUrl = query.redirectUrl
            const redirectUrlText = query.redirectUrlText

            const isValidUrl = (text) =>{
                try {
                    new URL(text);
                    return true;
                } catch (err) {
                    return false;
                }
            }

            let pass = true
            if (isValidUrl(redirectUrlText)) {
                const redirectUrlObj = new URL(decodeURIComponent(redirectUrl))
                const redirectUrlTextObj = new URL(decodeURIComponent(redirectUrlText))
                if (redirectUrlObj.hostname != redirectUrlTextObj.hostname) {
                    this.TEXTURL = redirectUrlTextObj.hostname
                    this.URL = redirectUrlObj.hostname
                    this.redirectConfirmDialog = true
                    pass = false
                }
            }
            if(pass) {
                this.redirect(redirectUrl)
            }
        },

        redirect(redirectUrl) {
            location.href = redirectUrl
        },

        openRedirectUrl() {
            let query = this.$route.query
            const redirectUrl = query.redirectUrl
            this.redirect(redirectUrl)
        },

        openRedirectUrlText() {
            let query = this.$route.query
            const redirectUrlText = query.redirectUrlText
            this.redirect(redirectUrlText)
        },

        closeDialog() {
            this.redirectConfirmDialog = false
            window.close()
        },
    },
}
</script>


<style scoped>
.mobileButtonFields {
    flex-direction: column-reverse;
}
</style>

