<template>
    <Panel class="notvisibility-header-panel">
    <div class="p-grid p-jc-center">
        <div class="container">
            <div style="margin-bottom: 10px;" class="p-d-flex">
                <div style="flex: 1;"> </div>
                <div style="">
                    <Dropdown v-model="selectedLanguage" :options="languages" optionLabel="name" optionValue="value" class="selectLanguageInputFormDropdown" panelClass="selectLanguageInputFormDropdownPanel" />
                </div>
            </div>
            <div class="p-col-12 p-grid no_padding no_margin" style="">
                <div class="p-col-6 no_padding" style="align-self: center;">
                    <label style="text-align: left; font-size: 18px; font-weight: normal; color:#0288D1;" > {{FormStatusButtonText}} ({{IsRead}})</label>
                </div>
                <div class="p-col-6 no_padding text-right" >
                    <Button class="p-button-outlined p-button-info" @click="FormStatusButtonClick" :disabled="getFormStatus!=1 || FromEmail == ''"> {{$t("postedFormScreen.Withdrawal")}} </Button>
                </div>
            </div>

            <div class="p-col-12" />
            <div v-bind:class="{'p-col-6': !isMobile.isMobile() , 'p-col-12': isMobile.isMobile() }" class="p-field p-grid no_margin no_padding p-nogutter">
                <div  class="p-col-12 p-field p-grid no_margin  p-nogutter ">
                    <editTemplateNoteInplace  :Type="'sent'" />
                </div>
            </div>

            <h1 style="text-align: left; font-size: 20px; font-weight: bold; padding-left: 8px; border-left: 4px solid #4a525a;" v-if="DocumentName">{{DocumentName}}</h1>
            <!-- <div class="p-field p-grid no_margin"> -->
            <!--     <div class="p-formgroup-inline"> -->
            <!--         <div class="p-field-checkbox"> -->
            <!--             <RadioButton value="kojin" v-model="userAttr" :disabled="true" /> -->
            <!--             <label>{{$t('inputForm.kojin')}}</label> -->
            <!--         </div> -->
            <!--         <div class="p-field-checkbox"> -->
            <!--             <RadioButton value="houjin" v-model="userAttr" :disabled="true" /> -->
            <!--             <label>{{$t('inputForm.houjin')}}</label> -->
            <!--         </div> -->
            <!--     </div> -->
            <!-- </div> -->
            <!--  -->
            <div class="p-field p-mb-1" >
                <div class="p-mb-2" style="font-size: 16px;">
                    {{$t('inputForm.select')}}
                </div>
                <div class="p-d-flex p-flex-column">
                    <template v-for="(userAttrInfo, attrIndex) in userAttrList" :key="attrIndex">
                        <div class="p-d-flex p-jc-start p-field-radiobutton p-mb-1">
                            <RadioButton :id="attrIndex" :value="userAttrInfo.value" v-model="userAttr"  :disabled="true"/>
                            <label :for="attrIndex" style="font-size: 16px;"> {{userAttrInfo.label}}</label>
                        </div>
                    </template>
                </div>
            </div>
            <div class="p-field p-grid no_margin">
                <div class="p-formgroup-inline">
                    <div v-for="type in appTypeList" class="p-field-checkbox" style="">
                        <Checkbox :id="type.id" name="type1" :value="type.value" v-model="appType" :disabled="true" />
                        <label :for="type.id" style="font-size: 16px;"> {{ type.label }}</label>
                    </div>
                </div>
                <div class="p-formgroup-inline align-item-center"> </div>
            </div>
            <div class="p-d-flex p-flex-column">
                <template v-for="(parts, el_index) in componentList">
                    <templatePanel :item="parts" :Id="parts.Id" :readOnly=true :showComponentName="false" >
                        <div class="p-col-12 p-grid no_margin no_padding" v-bind:class="{'no_padding': parts.ComponentType == 'Editor'}">
                            <template v-for="(item, el_index) in parts.items" >
                                <component
                                    v-bind:is="item.Xtype" 
                                    :item="item" 
                                    :readOnly=true 
                                    :Id="parts.Id" 
                                > </component>
                            </template>
                        </div>
                    </templatePanel>
                </template>
            </div>
            <InputViewFooter :AuthorName="AuthorName" />
        </div>
    </div>
    </Panel>
    <div style="text-align: center; position: fixed; bottom: 10px;left: 50%; transform: translate(-50%, -50%);" v-if="isMobile.isMobile()">
        <Button :label="$t('postedFormScreen.return2SentBox')" class="p-button-raised p-button-help p-button-text" @click="ToSentBox"/>
    </div>
</template>

<script>
import axios from 'axios'
import http from '../mixins/api'
import RadioButton from 'primevue/radiobutton';
import Checkbox from 'primevue/checkbox';
import formPanel from '@/components/inputFormParts/formPanel'
import textField from '@/components/templateFormParts/textField'
import textAreaField from '@/components/templateFormParts/textAreaField'
import simpleText from '@/components/templateFormParts/simpleText';
import dropDownField from '@/components/templateFormParts/dropDownField';
import multiSelectField from '@/components/templateFormParts/multiSelectField';
import dateField from '@/components/templateFormParts/dateField'
import timeField from '@/components/templateFormParts/timeField';
import datetimeField from '@/components/templateFormParts/datetimeField';
import fileField from '@/components/templateFormParts/fileField' 
import radioBoxGroupField from '@/components/templateFormParts/radioBoxGroupField' 
import checkBoxGroupField from '@/components/templateFormParts/checkBoxGroupField' 
import templatePanel from '@/components/templateFormParts/templateForm'
import fullEditor from '@/components/templateFormParts/fullEditor';
import fullEditor4Applicant from '@/components/templateFormParts/fullEditor4Applicant';
import InputViewFooter from '@/components/InputViewFooter';
import editTemplateNoteInplace from '@/components/templateFormParts/editTemplateNoteInplace';
import isMobile from '@/mixins/device'
import helper from "@/mixins/helper"
import store from "@/store"

import Panel from 'primevue/panel';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';


export default {
    name: 'postedFormScreen',
    components: {
        RadioButton,
        Checkbox,
        Button,
        formPanel,
        textField,
        textAreaField,
        simpleText,
        dropDownField,
        multiSelectField,
        dateField,
        timeField,
        datetimeField,
        checkBoxGroupField,
        fileField,
        radioBoxGroupField, 
        templatePanel,  
        fullEditor,
        fullEditor4Applicant,
        Panel,
        InputViewFooter,
        editTemplateNoteInplace,
        Dropdown,
    },
    data: function() {
        return {
            isMobile,
            AuthorName: "",
        }
    },
    async beforeRouteEnter(route, redirect, next) {
        const query = route.query
        const userId = query.userId
        const id = query.id
        const UUID = query.UUID
        const href = location.href
        let redirect_url = helper.trimToURL(["force_fresh", "password_hint"], href)
        localStorage.redirect_url = redirect_url;
        const res = await store.dispatch("viewPostedFormFromPostedUser/getFormById", {FormId: id, UUID: UUID})
        if(!res.Success) {
            // const href = location.href
            // let redirect_url = helper.trimToURL(["force_fresh", "password_hint"], href)
            // await store.dispatch("auth/authPoint", {redirect_url: redirect_url})
            next("/admin/top")
        } else {
            next(vm => vm.setData(res))
        }
    },
    computed: {
        DocumentName: {
            get: function() {
                return this.$store.getters["viewPostedFormFromPostedUser/getDocumentName"]
            }
        },
        form: {
            get: function() {
                return this.$store.getters["viewPostedFormFromPostedUser/getActiveForm"]
            }
        },
        userAttrList: {
            get: function() {
                return this.$store.getters["viewPostedFormFromPostedUser/getUserAttrList"]
            }
        },
        appTypeList: {
            get: function() {
                return this.$store.getters["viewPostedFormFromPostedUser/getAppTypeList"]
            },
        },
        appType: {
            get: function() {
                return this.$store.getters["viewPostedFormFromPostedUser/getAppType"] || []
            },
            set: function(value) {
                const list = []
                Object.keys(value).forEach(function (key) {
                    list.push(value[key])
                });
                this.$store.commit('viewPostedFormFromPostedUser/setAppType', value)
            }
        },
        userAttr: {
            get: function() {
                return this.$store.getters["viewPostedFormFromPostedUser/getUserAttr"]
            },
            set: function(value) {
                this.$store.commit('viewPostedFormFromPostedUser/setUserAttr', value)
            }
        },
        componentList: {
            get: function() {
                return this.$store.getters["viewPostedFormFromPostedUser/getComponents"]
            },
        },
        getFormStatus: {
            get: function() {
                return this.$store.getters["viewPostedFormFromPostedUser/getFormStatus"]
            },
        },
        FormStatusButtonText: {
            get: function() {
                // return this.$store.getters["viewPostedFormFromPostedUser/FormStatusButtonText"]
                const ButtonTextStatus = this.$store.getters["viewPostedFormFromPostedUser/ButtonTextStatus"]
                return this.$i18n.tc("postedFormScreen.ButtonStatusText." + ButtonTextStatus)
            }
        },
        IsRead: {
            get: function() {
                const IsRead = this.$store.getters["viewPostedFormFromPostedUser/getRead"]
                if(IsRead) {
                    return this.$i18n.tc("postedFormScreen.isReadForm")
                } else {
                    return this.$i18n.tc("postedFormScreen.notIsReadForm")
                }
            }
        },
        FromEmail: {
            get: function() {
                const FromEmail = this.$store.getters["viewPostedFormFromPostedUser/getFromEmail"]

                return FromEmail;
            }
        },
        selectedLanguage: {
            get: function() {
                const userLanguage = this.$store.getters["applicantStore/getUserLanguage"]
                this.$primevue.config.locale.today = this.$i18n.tc("locale.today");
                this.$primevue.config.locale.now = this.$i18n.tc("locale.now");
                this.$primevue.config.locale.save = this.$i18n.tc("locale.save");
                this.$primevue.config.locale.clear = this.$i18n.tc("locale.clear");

                return userLanguage
            },
            set: function(value) {
                this.$store.commit("applicantStore/setUserLanguage", value)
                this.$store.commit('viewPostedFormFromPostedUser/setUserAttrData', value)
            },
        },

        languages: {
            get: function() {
                return this.$store.getters["applicantStore/getUserLanguageList"]
            },
        },
    },

    methods: {
        setData: function(data) {
            console.log(data)
            this.AuthorName = data.AuthorName
            console.log(this.selectedLanguage)
            this.$store.commit("applicantStore/setInitUserLanguage")
            this.$store.commit('viewPostedFormFromPostedUser/setUserAttrData', this.selectedLanguage)
        },

        FormStatusButtonClick: async function() {
            const self = this;
            this.$confirm.require({
                autoFocus: "reject",
                message: self.$i18n.tc('postedFormScreen.isWithdrawal'),
                accept: async function() {
                    await self.dropApplication()
                },
                reject: () => {
                }
            })
        },

        dropApplication: async function() {
            const FormId = this.$store.getters["viewPostedFormFromPostedUser/getFormId"]
            const res = await http.post("/form/DropApplicationByFromUser", {FormId: FormId})
            if(res.FromStatus) {
                this.$store.commit("viewPostedFormFromPostedUser/setStatus", res.FromStatus)
            }
            if(res.Success) {
                this.$toast.add({severity:'success', summary: this.$i18n.tc("postedFormScreen.updateStatus"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            } else {
                this.$toast.add({severity:'error', summary: this.$i18n.tc("postedFormScreen.failedUpdateStatus"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
        },

        ToSentBox: function() {
            this.$router.push({ path: '/admin/sent' });
        }
    }
}
</script>

<style scoped>
</style>


