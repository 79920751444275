<template>
    <div class="p-d-flex  p-flex-column" style="width:100%; height: 100%; position: absolute;" v-if="Finished || BeforePublic" >
        <div class=" p-as-center" style="display: table;height:100%;">
            <div style="margin: 0 auto; display: table-cell;   vertical-align: middle;width: 100%; ">
                <div class="">
                    <div>
                        <div class="p-text-center loginDocumentText " style="font-size: 20px">
                            {{DocumentName}}
                        </div>
                        <div v-if="Finished" class="p-text-center loginLogoMini">{{$t('utils.finishedFormText')}}</div>
                        <div v-if="BeforePublic" class="p-text-center loginLogoMini">{{$t('utils.beforePublicFormText')}}</div>
                        <div class="p-text-center endDocumentId">
                            {{EndDocumentId}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BankInvoicePaperFooter />
    </div>
    <div class="p-d-flex  p-flex-column" style="width:100%; height: 100%; position: absolute;" v-else >
        <div class="container width-100-phone" style="padding-top: 5px; text-align: right;" >
            <Dropdown v-model="selectedLanguage" :options="languages" optionLabel="name" optionValue="value" class="selectLanguageInputFormDropdown"  panelClass="selectLanguageInputFormDropdownPanel" />
        </div>
        <div class=" p-as-center  width-100-phone" style="display: table;height:100%;">
            <div style="margin: 0 auto; display: table-cell;   vertical-align: middle;" class="container doorContainer">
                <div class="">
                    <div class="p-text-center loginDocumentText">
                        <h1 class="" style="text-align: left; font-size: 20px; font-weight: bold; padding-left: 8px; border-left: 4px solid #4a525a;word-break: break-word;"> {{DocumentName}} </h1>
                    </div>
                </div>
                <div class="p-field">
                    <div style="margin-top: 20px;" class=" p-grid p-nogutter">
                        <Button 
                            class="p-button-outlined p-button-info"
                            style="width: 100%; text-align: center;"
                            @click="ToFormByEmail"
                            :disabled="showEmailForm"
                        >
                            <span style="text-align: center; width:100%;">{{$t('door.ToInputPanel')}}</span>
                        </Button>
                    </div>
                </div>
                <div class="p-field">
                    <template v-if="showEmailForm">
                        <div style="" class=" p-grid p-nogutter">
                            <label style="font-size: 12px;" >{{$t('door.inputInfo')}}</label>
                        </div>
                        <div style="" class=" p-grid p-nogutter">
                            <InputText style="padding: 3px 2px;" class="p-col" @input="changeInputEmail" v-model="Email"  placeholder="Email" ref="certificationEmailField" />
                            <Button style="font-size: 14px;" type="button" class="p-button-outlined p-button-secondary" :label="$t('door.Send')" @click="sendCertificationNoClick" />
                        </div>
                        <div style="margin-top: 20px;" class=" p-grid p-nogutter">
                            <label style="font-size: 12px;" :class="{'disabledColor': !validInputCeNo || disabledCertificationButton, 'p-disabled': !validInputCeNo || disabledCertificationButton}" > {{$t('door.inputAuthNumber')}}</label>
                        </div>
                        <div class="p-grid p-nogutter">
                            <InputText style="padding: 3px 2px;" class="p-col" v-model="No" ref="validInputCeNoField" :placeholder="$t('door.AuthenticationNumber')" :disabled="!validInputCeNo || disabledCertificationButton" @input="onValidInputCeNoFieldChange" />
                            <Button type="button" style="font-size: 14px;" class="p-button-outlined p-button-secondary" @click="Certification" :disabled="!validInputCeNo || disabledCertificationButton" :label="$t('door.Authentication')" /> 
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <template v-if="displayProgressSpinner">
            <div class="miniProgressSpinnerContainer">
                <ProgressSpinner strokeWidth="4" />
            </div>
        </template>
        <div class="p-text-center loginLogoMiniHello">Hello Paperless World</div>
        <BankInvoicePaperFooter />
    </div>
</template>

<script>
// @ is an alias to /src
import store from '@/store'
import http from '../mixins/api'
import InputText from 'primevue/inputtext';
import ProgressSpinner from 'primevue/progressspinner';

import Button from 'primevue/button';
import BankInvoicePaperFooter from '@/components/BankInvoicePaperFooter';
import Dropdown from 'primevue/dropdown';

export default {
    name: 'Door',
    components: {
        Button,
        BankInvoicePaperFooter,
        ProgressSpinner,
        InputText,
        Dropdown,
        // PanelComponent,
        // FormComponent,
    },
    data: function(){
        return {
            DocumentName: "",
            EndDocumentId: "",
            showEmailForm: false,
            Email: null,
            SendEmail: null,
            TemplateId: null,
            validInputCeNo: false,
            No: null,
            Finished: false,
            BeforePublic: false,
            displayProgressSpinner: false,
            disabledCertificationButton: false,
        }
    },
    computed: {
        selectedLanguage: {
            get: function() {
                return this.$store.getters["applicantStore/getUserLanguage"]
            },
            set: function(value) {
                this.$store.commit("applicantStore/setUserLanguage", value)
            },
        },

        languages: {
            get: function() {
                return this.$store.getters["applicantStore/getUserLanguageList"]
            },
        }
    },
    created() {
        const self = this 
        window.addEventListener('beforeunload', function(e) {
            e.returnValue = self.$i18n.tc("utils.backInInputForm");
        });
    },
    async beforeRouteEnter(route, redirect, next) {
        const query = route.query
        const id = query.id
        const DepartmentUUID = route.params.departmentUUID
        const LinkUUID = route.params.linkUUID
        localStorage.removeItem('formUserToken')
        localStorage.removeItem('formUserInfo')
        const res_url = await http.post("/form/GetFormURLByTemplateIdInDoor", {id: id, skipEmailAuth: false, DepartmentUUID: DepartmentUUID, LinkUUID: LinkUUID }, {}, false, false)
        if(res_url.Success) {
            if(route.path != res_url.Path) {
                next(res_url.Path)
                return
            }
        } else {
            next({path: "/notfoundforms", query: {link: location.href}})
            return
        }
        const res = await http.post('/form/IsPublicedFormTemplate', {TemplateId: id, DepartmentUUID: DepartmentUUID, LinkUUID: LinkUUID}, {}, false, false);
        if(res.Success) {
            const isPassedBeforeForm = store.getters['applicantStore/isPassedBeforeForm']
            if (!isPassedBeforeForm) {
                const res_passed = await http.post("/form/checkFormTemplatePassword", {DepartmentUUID: DepartmentUUID, LinkUUID: LinkUUID}, {}, false, false)
                if (res_passed.Success) {
                    if(res_passed.NonePassword) {
                        store.commit('applicantStore/setIsPassedBeforeForm', true)
                    } else {
                        next({name: "form_validation_doors", params: {departmentUUID: DepartmentUUID, linkUUID: LinkUUID}})
                        return 
                    }
                } else {
                    next({path: "/notfoundforms", query: {link: location.href}})
                    return
                }
            }
            
            const isPassedBeforeFormMessage = store.getters['applicantStore/isPassedBeforeFormMessage']
            if (!isPassedBeforeFormMessage) {
                const res_passed = await http.post("/form/checkFormTemplatePreFormMessage", {DepartmentUUID: DepartmentUUID, LinkUUID: LinkUUID}, {}, false, false)
                if (res_passed.Success) {
                    if(res_passed.IsActive) {
                        next({name: "form_pre_message", params: {departmentUUID: DepartmentUUID, linkUUID: LinkUUID}})
                        return 
                    } else {
                        store.commit('applicantStore/setIsPassedBeforeFormMessage', true)
                    }
                } else {
                    next({path: "/notfoundforms", query: {link: location.href}})
                    return
                }
            }

            next(vm => vm.setData({id: res.TemplateId, DocumentName: res.DocumentName}))
        } else {
            next(vm => vm.wrongPage({DocumentName: res.DocumentName, TemplateId: res.TemplateId, isBefore: res.isBefore}))
        }
    },
    methods: {
        wrongPage: function(data) {
            this.DocumentName = data.DocumentName
            if (data.TemplateId) {
                let EndDocumentId = ""
                for (let i = 0; i < data.TemplateId.length; i++) {
                    if (i < 4 || i >= data.TemplateId.length - 4) {
                        EndDocumentId += data.TemplateId[i]
                    } else if (i < 7) {
                        EndDocumentId += "."
                    }
                }
                this.EndDocumentId = EndDocumentId
            }
            if (data.isBefore) {
                this.BeforePublic = true
            } else {
                this.Finished = true
            }
        },
        setData: function(data) {
            this.TemplateId = data.id
            this.DocumentName = data.DocumentName
            this.$store.commit("applicantStore/setInitUserLanguage")
        },
        ToFormByEmail:async function(e) {
            await (this.showEmailForm = !this.showEmailForm)
            if(this.showEmailForm) {
                this.$refs.certificationEmailField.$el.focus()
            }
        },
        sendCertificationNoClick: async function(e){
            const Email = this.Email;
            this.displayProgressSpinner = true;
            const res = await http.post('/form/SendCertificationNumber', {Email: Email, TemplateId: this.TemplateId}, {}, false, false);
            if(res.Success) {
                await(this.validInputCeNo = true);
                this.SendEmail = Email;
                this.$toast.add({severity:'success', summary: this.$i18n.tc("utils.sendMailSuccess"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
                localStorage.formUserToken = res.UserToken
                localStorage.formUserInfo = res.User
                this.$refs.validInputCeNoField.$el.focus()
            } else {
                if(res.Message) {
                    this.$toast.add({severity: 'error', summary: res.Message, life: this.$constants.errorHelperLife, group: this.$constants.defaultToastGroup});
                }
            }
            this.displayProgressSpinner = false;
        },

        Certification: async function(e, isSilent) {
            this.disabledCertificationButton = true;
            this.displayProgressSpinner = true;
            const res = await http.post('/form/ConfirmCertificationNumber', {
                Email: this.SendEmail, No: this.No,
                TemplateId: this.TemplateId,
                DepartmentUUID: this.$route.params.DepartmentUUID,
                LinkUUID: this.$route.params.LinkUUID
            }, {}, false, false);
            if(res.Success) {
                if(this.$route.params.departmentUUID && this.$route.params.linkUUID) {
                    this.$router.push({ path: '/inputforms/' + this.$route.params.departmentUUID + '/' + this.$route.params.linkUUID });
                } else {
                    this.$router.push({ path: '/inputform', query: { id: this.TemplateId } });
                }
            } else {
                if(!isSilent)
                    this.$toast.add({severity:'error', summary: this.$i18n.tc("door.failedCertificationNumber"), life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }
            this.displayProgressSpinner = false;
            this.disabledCertificationButton = false;
        },

        changeInputEmail: function() {
            if(this.Email && this.Email != this.Email.trim()) {
                this.Email = this.Email.trim();
            }
        },

        onValidInputCeNoFieldChange: async function(e) {
            const value = e.target.value;
            if(value && value.length == 4  && !isNaN(Number(value))) {
                this.No = value
                await this.Certification({}, true)
            }

        },
    }
}
</script>

<style scoped>
.endDocumentId {
    font-size: 12px;
    font-weight: normal;
    color: #666666;
    padding-bottom: 0px;
    font-family: 'MS P明朝', courier,'SF Pro Icons','Helvetica Neue',Helvetica,Arial,sans-serif;
}
.loginDocumentText {
    text-align: center;
    font-size: 46pt;
    font-style: normal;
    font-weight: 300;
    color: #666666;
    /* font-family: 'MS P明朝',courier,'SF Pro Icons','Helvetica Neue',Helvetica,Arial,sans-serif; */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    max-width: 100%;
}
.disabledColor {
    color: #495057;
}
</style>
